import React from "react";
import { useModal, useToast } from "@slid/slid-ips";
import { useNotification } from "utils/customHook/useNotification";
import { useMediaQuery } from "react-responsive";
import { useConfirmPrivilege } from "./utils/customHook/useConfirmPrivilege";
import { useNoteDownload } from "hooks";
import useMyNoteStore from "store/useMyNoteStore";
import useWhisperSLTStore from "store/useWhisperSLTStore";

export const withHooks = (Component: any) => {
  return (props: any) => {
    const { closeModal, showModal, modalOn } = useModal();
    const { showToast } = useToast();
    const { showNotification } = useNotification();
    const { showInsufficientPrivilegeModal, confirmPrivilege } = useConfirmPrivilege();
    const { downloadCurrentNoteToPDF } = useNoteDownload();
    const isMobile = useMediaQuery({ query: "(max-width:799px)" });
    const { setDeleteNoteLocation } = useMyNoteStore();
    const { isWhisperSLTSupported, setIsWhisperSLTSupported } = useWhisperSLTStore();

    return (
      <Component
        modalOn={modalOn}
        closeModal={closeModal}
        showModal={showModal}
        showToast={showToast}
        showNotification={showNotification}
        confirmPrivilege={confirmPrivilege}
        showInsufficientPrivilegeModal={showInsufficientPrivilegeModal}
        downloadCurrentNoteToPDF={downloadCurrentNoteToPDF}
        isMobile={isMobile}
        setDeleteNoteLocation={setDeleteNoteLocation}
        isWhisperSLTSupported={isWhisperSLTSupported}
        setIsWhisperSLTSupported={setIsWhisperSLTSupported}
        {...props}
      />
    );
  };
};
