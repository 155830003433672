import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";
const appleIapPayments_endpoint = `apple-iap-payments/`;

export const ServerInterface_createPaymentForAppleIAP = async ({ data }) => {
  const payload = data;
  let createPaymentData = null;
  try {
    const createPaymentResponse = await slidAxios.post(appleIapPayments_endpoint, payload);
    createPaymentData = createPaymentResponse.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      if (!createPaymentData) createPaymentData = {};
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          createPaymentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // for missing field and incorrect data format
          createPaymentData.error_message = "APPLE_IAP_INCORRECT_DATA_FORMAT";
          break;
        case 403:
          createPaymentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          createPaymentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      createPaymentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      createPaymentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return createPaymentData;
  }
};
