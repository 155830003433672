import store from "redux/store";
import * as Sentry from "@sentry/browser";
export enum MembershipType {
  break = "break",
  trial = "trial",
  basic = "basic",
  pro = "pro",
  standard = "standard",
  premium = "premium",
}

export enum SlidFeatures {
  //note features
  noteView = "noteView",
  noteDeletion = "noteDeletion",
  notePermanentDeletion = "notePermanentDeletion",
  noteRestoration = "noteRestoration",
  noteCreation = "noteCreation",
  noteEditing = "noteEditing",
  noteTitleUpdate = "noteTitleUpdate",
  noteMove = "noteMove",
  pdfExport = "pdfExport",
  wordExport = "wordExport",
  imageExport = "imageExport",
  markDownExport = "markDownExport",
  noteClipboardCopy = "noteClipboardCopy",
  noteSharing = "noteSharing",
  noteSearching = "noteSearching",
  accessVideoDocument = "accessVideoDocument",
  viewNoteHistory = "viewNoteHistory", // will be removed
  makeNoteHistory = "makeNoteHistory",
  //editor features
  oneClickCapture = "oneClickCapture",
  snipCapture = "snipCapture",
  imageUpload = "imageUpload",
  pdfUpload = "pdfUpload",
  imageDrawing = "imageDrawing",
  imageTimeStamp = "imageTimeStamp",
  imageOcr = "imageOcr",
  clipRecording = "clipRecording",
  smartLiveText = "smartLiveText",
  autoNotes = "autoNotes",
  //folder features
  folderDeletion = "folderDeletion",
  folderCreation = "folderCreation",
  folderMove = "folderMove",
  folderTitleUpdate = "folderTitleUpdate",
  folderColorUpdate = "folderColorUpdate",
}

const BREAK_USER_PRIVILEGES_MAP = new Map<SlidFeatures, boolean>([
  //note features
  [SlidFeatures.noteView, true],
  [SlidFeatures.noteDeletion, true],
  [SlidFeatures.notePermanentDeletion, true],
  [SlidFeatures.noteRestoration, true],
  [SlidFeatures.noteCreation, false],
  [SlidFeatures.noteEditing, false],
  [SlidFeatures.noteTitleUpdate, false],
  [SlidFeatures.noteMove, false],
  [SlidFeatures.accessVideoDocument, false],
  [SlidFeatures.pdfExport, true],
  [SlidFeatures.wordExport, true],
  [SlidFeatures.imageExport, true],
  [SlidFeatures.markDownExport, true],
  [SlidFeatures.noteClipboardCopy, true],
  [SlidFeatures.noteSharing, true],
  [SlidFeatures.noteSearching, true],
  [SlidFeatures.makeNoteHistory, true],
  //editor features
  [SlidFeatures.oneClickCapture, false],
  [SlidFeatures.snipCapture, false],
  [SlidFeatures.imageUpload, false],
  [SlidFeatures.pdfUpload, false],
  [SlidFeatures.imageDrawing, false],
  [SlidFeatures.imageTimeStamp, false],
  [SlidFeatures.imageOcr, false],
  [SlidFeatures.clipRecording, false],
  [SlidFeatures.smartLiveText, false],
  [SlidFeatures.autoNotes, false],
  // folder features
  [SlidFeatures.folderDeletion, true],
  [SlidFeatures.folderCreation, false],
  [SlidFeatures.folderMove, false],
  [SlidFeatures.folderTitleUpdate, false],
  [SlidFeatures.folderColorUpdate, false],
]);

const TRIAL_USER_PRIVILEGES_MAP = new Map<SlidFeatures, boolean>([
  [SlidFeatures.noteView, true],
  [SlidFeatures.noteDeletion, true],
  [SlidFeatures.notePermanentDeletion, true],
  [SlidFeatures.noteRestoration, true],
  [SlidFeatures.noteCreation, true],
  [SlidFeatures.noteEditing, true],
  [SlidFeatures.noteTitleUpdate, true],
  [SlidFeatures.noteMove, true],
  [SlidFeatures.accessVideoDocument, true],
  [SlidFeatures.pdfExport, true],
  [SlidFeatures.wordExport, true],
  [SlidFeatures.imageExport, true],
  [SlidFeatures.markDownExport, true],
  [SlidFeatures.noteClipboardCopy, true],
  [SlidFeatures.noteSharing, true],
  [SlidFeatures.noteSearching, true],
  [SlidFeatures.makeNoteHistory, true],
  //editor features
  [SlidFeatures.oneClickCapture, true],
  [SlidFeatures.snipCapture, true],
  [SlidFeatures.imageUpload, true],
  [SlidFeatures.pdfUpload, true],
  [SlidFeatures.imageDrawing, true],
  [SlidFeatures.imageTimeStamp, true],
  [SlidFeatures.imageOcr, true],
  [SlidFeatures.clipRecording, true],
  [SlidFeatures.smartLiveText, true],
  [SlidFeatures.autoNotes, true],
  // folder features
  [SlidFeatures.folderDeletion, true],
  [SlidFeatures.folderCreation, true],
  [SlidFeatures.folderMove, true],
  [SlidFeatures.folderTitleUpdate, true],
  [SlidFeatures.folderColorUpdate, true],
]);

const BASIC_USER_PRIVILEGES_MAP = new Map<SlidFeatures, boolean>([
  [SlidFeatures.noteView, true],
  [SlidFeatures.noteDeletion, true],
  [SlidFeatures.notePermanentDeletion, true],
  [SlidFeatures.noteRestoration, true],
  [SlidFeatures.noteCreation, true],
  [SlidFeatures.noteEditing, true],
  [SlidFeatures.noteTitleUpdate, true],
  [SlidFeatures.noteMove, true],
  [SlidFeatures.accessVideoDocument, true],
  [SlidFeatures.pdfExport, true],
  [SlidFeatures.wordExport, true],
  [SlidFeatures.imageExport, true],
  [SlidFeatures.markDownExport, true],
  [SlidFeatures.noteClipboardCopy, true],
  [SlidFeatures.noteSharing, true],
  [SlidFeatures.noteSearching, true],
  [SlidFeatures.makeNoteHistory, true],
  //editor features
  [SlidFeatures.oneClickCapture, true],
  [SlidFeatures.snipCapture, true],
  [SlidFeatures.imageUpload, true],
  [SlidFeatures.pdfUpload, true],
  [SlidFeatures.imageDrawing, true],
  [SlidFeatures.imageTimeStamp, true],
  [SlidFeatures.imageOcr, true],
  [SlidFeatures.clipRecording, false],
  [SlidFeatures.smartLiveText, false],
  [SlidFeatures.autoNotes, false],
  // folder features
  [SlidFeatures.folderDeletion, true],
  [SlidFeatures.folderCreation, true],
  [SlidFeatures.folderMove, true],
  [SlidFeatures.folderTitleUpdate, true],
  [SlidFeatures.folderColorUpdate, true],
]);

const PRO_USER_PRIVILEGES_MAP = new Map<SlidFeatures, boolean>([
  [SlidFeatures.noteView, true],
  [SlidFeatures.noteDeletion, true],
  [SlidFeatures.notePermanentDeletion, true],
  [SlidFeatures.noteRestoration, true],
  [SlidFeatures.noteCreation, true],
  [SlidFeatures.noteEditing, true],
  [SlidFeatures.noteTitleUpdate, true],
  [SlidFeatures.noteMove, true],
  [SlidFeatures.accessVideoDocument, true],
  [SlidFeatures.pdfExport, true],
  [SlidFeatures.wordExport, true],
  [SlidFeatures.imageExport, true],
  [SlidFeatures.markDownExport, true],
  [SlidFeatures.noteClipboardCopy, true],
  [SlidFeatures.noteSharing, true],
  [SlidFeatures.noteSearching, true],
  [SlidFeatures.makeNoteHistory, true],
  //editor features
  [SlidFeatures.oneClickCapture, true],
  [SlidFeatures.snipCapture, true],
  [SlidFeatures.imageUpload, true],
  [SlidFeatures.pdfUpload, true],
  [SlidFeatures.imageDrawing, true],
  [SlidFeatures.imageTimeStamp, true],
  [SlidFeatures.imageOcr, true],
  [SlidFeatures.clipRecording, true],
  [SlidFeatures.smartLiveText, true],
  [SlidFeatures.autoNotes, true],
  // folder features
  [SlidFeatures.folderDeletion, true],
  [SlidFeatures.folderCreation, true],
  [SlidFeatures.folderMove, true],
  [SlidFeatures.folderTitleUpdate, true],
  [SlidFeatures.folderColorUpdate, true],
]);

const STANDARD_USER_PRIVILEGES_MAP = new Map<SlidFeatures, boolean>([
  [SlidFeatures.noteView, true],
  [SlidFeatures.noteDeletion, true],
  [SlidFeatures.notePermanentDeletion, true],
  [SlidFeatures.noteRestoration, true],
  [SlidFeatures.noteCreation, true],
  [SlidFeatures.noteEditing, true],
  [SlidFeatures.noteTitleUpdate, true],
  [SlidFeatures.noteMove, true],
  [SlidFeatures.accessVideoDocument, true],
  [SlidFeatures.pdfExport, true],
  [SlidFeatures.wordExport, true],
  [SlidFeatures.imageExport, true],
  [SlidFeatures.markDownExport, true],
  [SlidFeatures.noteClipboardCopy, true],
  [SlidFeatures.noteSharing, true],
  [SlidFeatures.noteSearching, true],
  [SlidFeatures.makeNoteHistory, true],
  //editor features
  [SlidFeatures.oneClickCapture, true],
  [SlidFeatures.snipCapture, true],
  [SlidFeatures.imageUpload, true],
  [SlidFeatures.pdfUpload, true],
  [SlidFeatures.imageDrawing, true],
  [SlidFeatures.imageTimeStamp, true],
  [SlidFeatures.imageOcr, true],
  [SlidFeatures.clipRecording, false],
  [SlidFeatures.smartLiveText, false],
  [SlidFeatures.autoNotes, false],
  // folder features
  [SlidFeatures.folderDeletion, true],
  [SlidFeatures.folderCreation, true],
  [SlidFeatures.folderMove, true],
  [SlidFeatures.folderTitleUpdate, true],
  [SlidFeatures.folderColorUpdate, true],
]);

const PREMIUM_USER_PRIVILEGES_MAP = new Map<SlidFeatures, boolean>([
  [SlidFeatures.noteView, true],
  [SlidFeatures.noteDeletion, true],
  [SlidFeatures.notePermanentDeletion, true],
  [SlidFeatures.noteRestoration, true],
  [SlidFeatures.noteCreation, true],
  [SlidFeatures.noteEditing, true],
  [SlidFeatures.noteTitleUpdate, true],
  [SlidFeatures.noteMove, true],
  [SlidFeatures.accessVideoDocument, true],
  [SlidFeatures.pdfExport, true],
  [SlidFeatures.wordExport, true],
  [SlidFeatures.imageExport, true],
  [SlidFeatures.markDownExport, true],
  [SlidFeatures.noteClipboardCopy, true],
  [SlidFeatures.noteSharing, true],
  [SlidFeatures.noteSearching, true],
  [SlidFeatures.makeNoteHistory, true],
  //editor features
  [SlidFeatures.oneClickCapture, true],
  [SlidFeatures.snipCapture, true],
  [SlidFeatures.imageUpload, true],
  [SlidFeatures.pdfUpload, true],
  [SlidFeatures.imageDrawing, true],
  [SlidFeatures.imageTimeStamp, true],
  [SlidFeatures.imageOcr, true],
  [SlidFeatures.clipRecording, true],
  [SlidFeatures.smartLiveText, true],
  [SlidFeatures.autoNotes, true],
  // folder features
  [SlidFeatures.folderDeletion, true],
  [SlidFeatures.folderCreation, true],
  [SlidFeatures.folderMove, true],
  [SlidFeatures.folderTitleUpdate, true],
  [SlidFeatures.folderColorUpdate, true],
]);

const USER_PRIVILEGES_MAP = new Map<MembershipType, Map<SlidFeatures, boolean>>();

USER_PRIVILEGES_MAP.set(MembershipType.break, BREAK_USER_PRIVILEGES_MAP);

USER_PRIVILEGES_MAP.set(MembershipType.trial, TRIAL_USER_PRIVILEGES_MAP);

USER_PRIVILEGES_MAP.set(MembershipType.basic, BASIC_USER_PRIVILEGES_MAP);

USER_PRIVILEGES_MAP.set(MembershipType.pro, PRO_USER_PRIVILEGES_MAP);

USER_PRIVILEGES_MAP.set(MembershipType.standard, STANDARD_USER_PRIVILEGES_MAP);

USER_PRIVILEGES_MAP.set(MembershipType.premium, PREMIUM_USER_PRIVILEGES_MAP);

const has = (feature: SlidFeatures): boolean => {
  const userData = store.getState().slidGlobal.userData;
  const membershipType: MembershipType = userData?.payment;
  if (membershipType) {
    const userPrivileges = USER_PRIVILEGES_MAP.get(membershipType)!;
    if (!userPrivileges) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("message", `Privileges of ${membershipType} membership type are not defined in user privileges manager`);
        Sentry.captureMessage("Error reading user privileges");
      });
      return true; // return true to avoid false negatives
    }
    // checks if feature name is missing in the privileges map
    if (!userPrivileges.has(feature)) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("message", `Privileges of ${feature} are not defined in user privileges manager`);
        scope.setExtra("userData", userData);
        Sentry.captureMessage("Error reading user privileges");
      });
      return true; // return true to avoid false negatives
    }
    return userPrivileges.get(feature) || false;
  } else {
    //If user data is not yet fetched, then return true to avoid false negatives
    return true;
  }
};

const USER_PRIVILEGES = {
  has,
};

export default USER_PRIVILEGES;
