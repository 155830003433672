import { UserMembership } from "types/globals";
import { useHistory } from "react-router-dom";
import { activateDayPass, fetchSubscription, updateSubscription } from "redux/actions/pricingActions";
import { alertProcessing } from "../../components/alerts/index";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

import { EmojiModalProps, ModalTypes } from "@slid/slid-ips";
import { CustomModalProps } from "@slid/slid-ips";
import env from "config/env";
import i18n from "config/i18n/i18n";
import { deviceType } from "react-device-detect";
import store from "redux/store";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/browser";
import ChannelService from "config/channel-talk";
import { MembershipType } from "../privilegeManager";
import { isMobileApp, isTabletApp } from "utils/utils";
import { PlanTypeProps } from "components/pricing/types";
import { CheckoutButtonOriginProps } from "components/buttons/CheckoutButton";

interface ShowModalProps {
  showModal: Function;
  closeModal: Function;
  history?: ReturnType<typeof useHistory>;
  eventOrigin?: string;
  dispatch?: any;
  currentDocumentKey?: any;
  customComponentContent?: any;
  parameter?: any;
  deviceType?: "desktop" | "mobile" | undefined;
  planType?: PlanTypeProps;
  origin?: CheckoutButtonOriginProps;
}

const t = i18n.t.bind(i18n);

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
const shouldShowMobileSizeModal = deviceType === "mobile" || (isMobileApp() && !isTabletApp());

export const showUserHasPlanActivatedModal = async ({ showModal, closeModal, planType, origin }: ShowModalProps) => {
  const modalProps: EmojiModalProps = {
    title: t("PurchaseThroughLiveChatModalTitle", { ns: "Modal" }),
    text: t("PurchaseThroughLiveChatModalBody", { ns: "Modal" }),
    type: ModalTypes.emojiModal,
    emoji: "✅",
    primaryButton: {
      text: t("LiveChatSupport", { ns: "Modal" }),
      callBack: () => {
        window.open(`https://slid.channel.io/lounge`);
        closeModal();
        trackEvent({
          eventType: "Click LIVE CHAT SUPPORT in upgrade or downgrade modal",
          eventProperties: {
            selected_plan: planType,
            position: origin === "plan-option" ? "main cta" : "header cta",
          },
        });
      },
    },
    secondaryButton: {
      text: t("Close", { ns: "Modal" }),
      callBack: () => {
        closeModal();
      },
    },
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
  };
  showModal(modalProps);
};

export const showPaymentModal = async ({ showModal, closeModal, customComponentContent }: ShowModalProps) => {
  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    customComponent: customComponentContent,
    closableDim: true,
  };
  showModal(modalProps);
};

export const showPaymentSuccessModal = async ({ showModal, closeModal, parameter }: ShowModalProps) => {
  const userData = await store.getState().slidGlobal.userData;

  const isUserInPricingPage = window.location.pathname.includes("pricing") || window.location.pathname.includes("/legacy-pricing") || window.location.pathname.includes("/misasi-pricing");
  trackEvent({
    eventType: eventTypes.show.PAYMENT_SUCCESS_MODAL,
    eventProperties: {
      membership: parameter.membership === "daypass" ? "24H Pass" : parameter.membership,
      from: isUserInPricingPage ? "pricing page" : "My Notes",
      length: parameter.membership !== UserMembership.daypass ? parameter.length : undefined,
      size: parameter.membership === UserMembership.daypass ? parameter.length : undefined,
    },
  });

  let modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    emoji: t("PaymentSucessEmoji", { ns: "Modal" }),
    title: t("PaymentSuccessTitle", { ns: "Modal" }),
    text: t("PaymentSuccessText", { ns: "Modal", membership: `${t(parameter.membership, { ns: "common" })}`, length: parameter.length }),
    closableDim: false,
    primaryButton: {
      text: t("PaymentSuccessPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click CHECK MY PLAN in payment success modal" });
        closeModal();
        window.location.replace("/docs?mode=myAccount");
      },
    },
    secondaryButton: {
      text: t("PaymentSuccessSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click GO TO MY NOTE in payment success modal" });
        closeModal();
        window.location.replace("/docs");
      },
    },
  };

  // handle daypass case:
  if (parameter.membership === "daypass") {
    if (parameter.length !== 1 && parameter.length !== 5) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_DAYPASS_LENGTH_ERROR");
      });
    }
    if (userData?.payment === UserMembership.trial) {
      // show different text for trial user who bought daypass since they cannot use it directly.
      modalProps.text = t("DaypassPaymentSuccessTextForTrial", { ns: "Modal", number: parameter.length });
    } else {
      modalProps.text = t("PaymentSuccessTextForDayPass", { ns: "Modal", number: parameter.length });
      modalProps.primaryButton = {
        text: t("PaymentSuccessPrimaryButtonForDayPass", { ns: "Modal" }),
        callBack: async () => {
          trackEvent({ eventType: eventTypes.click.START_NOW_IN_PAYMENT_SUCCESS_MODAL });
          await store.dispatch(activateDayPass());
          closeModal();
          window.location.replace("/docs");
        },
      };
      modalProps.secondaryButton = {
        text: t("PaymentSuccessSecondaryButtonForDayPass", { ns: "Modal" }),
        callBack: () => {
          trackEvent({ eventType: eventTypes.click.LATER_IN_PAYMENT_SUCCESS_MODAL });
          closeModal();
          window.location.replace("/docs");
        },
      };
    }
  }

  showModal(modalProps);
};

export const showPaymentCancelledModal = async ({ showModal, closeModal }: ShowModalProps) => {
  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    emoji: t("PaymentCancelledEmoji", { ns: "Modal" }),
    title: t("PaymentCancelledTitle", { ns: "Modal" }),
    text: t("PaymentCancelledText", { ns: "Modal" }),
    closableDim: false,
    primaryButton: {
      text: t("PaymentCancelledPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({
          eventType: "Click CONFIRM in payment cancel modal",
        });
        closeModal();
        // window.location.replace("/pricing");
      },
    },
    secondaryButton: {
      text: shouldShowMobileSizeModal ? t("PaymentCancelledSecondaryButtonMobile", { ns: "Modal" }) : t("PaymentCancelledSecondaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({
          eventType: "Click LIVE CHAT SUPPORT in payment cancel modal",
        });
        closeModal();
        window.open("https://slid.channel.io/lounge");
        // window.location.replace(`${SLID_WEB_APP_URL}/pricing?mode=chatOpen`);
      },
    },
  };
  showModal(modalProps);
};

export const showPaymentFailedModal = async ({ showModal, closeModal }: ShowModalProps) => {
  const modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    emoji: t("PaymentFailedEmoji", { ns: "Modal" }),
    title: t("PaymentFailedTitle", { ns: "Modal" }),
    text: t("PaymentFailedText", { ns: "Modal" }),
    closableDim: false,
    primaryButton: {
      text: t("PaymentFailedPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click CONFIRM in payment error modal" });
        closeModal();
        window.location.replace("/pricing");
      },
    },
    secondaryButton: {
      text: shouldShowMobileSizeModal ? t("PaymentFailedSecondaryButton", { ns: "Modal" }) : t("PaymentFailedSecondaryButtonMobile", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click LIVE CHAT SUPPORT in payment error modal" });
        closeModal();
        window.open(`${SLID_WEB_APP_URL}/docs?mode=chatOpen`);
      },
    },
  };
  showModal(modalProps);
};

export const showSubscriptionCancelModal = async ({ showModal, closeModal, history, dispatch }: ShowModalProps) => {
  let cancelButtonClicked = false;

  const lang = await store.getState().slidGlobal.lang;
  const userData = await store.getState().slidGlobal.userData;
  const subscriptionData = await store.getState().pricing.subscriptionData;
  const isUsingOldPlan = userData?.payment === MembershipType.basic || userData?.payment === MembershipType.pro;
  const modalText = isUsingOldPlan
    ? shouldShowMobileSizeModal
      ? t("CancelSubscriptionModalMobileVersionBodyForOldPlan", { ns: "Modal" })
      : t("CancelSubscriptionModalBodyForOldPlan", { ns: "Modal" })
    : t("CancelSubscriptionModalBody", { ns: "Modal" });

  const cancelSbuscription = async () => {
    // To prevent multiple click from user.
    if (cancelButtonClicked) return;

    trackEvent({
      eventType: eventTypes.click.CANCEL_SUBSCRIPTION_IN_SUBSCRIPTION_CANCEL_CONFIRM_MODAL,
    });

    cancelButtonClicked = true;
    closeModal();
    alertProcessing({ clickableDim: false });

    await dispatch(
      updateSubscription({
        data: {
          subscription_key: subscriptionData.subscription_key,
          is_active: false,
        },
      })
    );
    Swal.close();
    await showSubscriptionCancelResultModal({ showModal, closeModal, history });
  };

  let modalProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("CancelSubscriptionModalIcon", { ns: "Modal" }),
    title: t("CancelSubscriptionModalTitle", { ns: "Modal" }),
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    text: modalText,
    primaryButton: {
      text: t(shouldShowMobileSizeModal ? "CancelSubscriptionModalMobileVersionPrimaryButton" : "CancelSubscriptionModalPrimaryButton", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: eventTypes.click.KEEP_SUBSCRIPTION_IN_SUBSCRIPTION_CANCEL_CONFIRM_MODAL });
        closeModal();
      },
    },
    secondaryButton: {
      text: t(shouldShowMobileSizeModal ? "CancelSubscriptionModalMobileVersionSecondaryButton" : "CancelSubscriptionModalSecondaryButton", { ns: "Modal" }),
      callBack: () => cancelSbuscription(),
    },
  };

  if (lang !== "ko" && shouldShowMobileSizeModal) {
    modalProps = {
      ...modalProps,
      primaryButton: {
        text: t(shouldShowMobileSizeModal ? "CancelSubscriptionModalMobileVersionSecondaryButton" : "CancelSubscriptionModalSecondaryButton", { ns: "Modal" }),
        callBack: () => cancelSbuscription(),
      },
      secondaryButton: undefined,
    };
  }
  showModal(modalProps);
};

export const showSubscriptionCancelResultModal = async ({ showModal, closeModal, history }: ShowModalProps) => {
  const lang = await store.getState().slidGlobal.lang;
  const userData = await store.getState().slidGlobal.userData;
  const subscriptionData = await store.getState().pricing.subscriptionData;

  if (subscriptionData && subscriptionData.message === "Subscription Cancelled") {
    const downgradeDate = new Date(subscriptionData.next_billing_at).toISOString().slice(0, 10);
    trackEvent({
      eventType: eventTypes.success.SUBSCRIPTION_CANCELLATION,
      eventProperties: {
        membership: userData.payment,
      },
    });
    let modalProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("CancelSubscriptionCompleteModalIcon", { ns: "Modal" }),
      title: t("CancelSubscriptionCompleteModalTitle", { ns: "Modal", membership: `${t(userData.payment, { ns: "common" })}` }),
      text: t("CancelSubscriptionCompleteModalBody", { ns: "Modal", membership: `${t(userData.payment, { ns: "common" })}`, date: downgradeDate }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      primaryButton: {
        text: t(shouldShowMobileSizeModal ? "CancelSubscriptionCompleteModalMobileVersionPrimaryButton" : "CancelSubscriptionCompleteModalPrimaryButton", { ns: "Modal" }),
        callBack: () => {
          trackEvent({ eventType: eventTypes.click.CHECKOUT_PLAN_IN_SUBSCRIPTION_CANCELLATION_COMPLETED_MODAL });
          closeModal();
          window.open("/pricing");
        },
      },
    };

    if (lang === "ko") {
      modalProps = {
        ...modalProps,
        secondaryButton: {
          text: t("CancelSubscriptionCompleteModalSecondaryButton", { ns: "Modal" }),
          callBack: () => closeModal(),
        },
      };
    }
    showModal(modalProps);
  } else {
    trackEvent({ eventType: eventTypes.show.SUBSCRIPTION_CANCEL_FAIL_MODAL });
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      scope.setExtra("rsp", subscriptionData);
      scope.setExtra("error_msg", subscriptionData.message);
      Sentry.captureMessage("SLID_WEB_CANCEL_SUBSCRIPTION_ERROR");
    });
    const modalProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("CancelSubscriptionFailedModalIcon", { ns: "Modal" }),
      title: t("CancelSubscriptionFailedModalTitle", { ns: "Modal" }),
      text: t("CancelSubscriptionFailedModalBody", { ns: "Modal" }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      primaryButton: {
        text: t("CancelSubscriptionFailedModalPrimaryButton", { ns: "Modal" }),
        callBack: async () => {
          await store.dispatch(fetchSubscription());
          closeModal();
        },
      },
      secondaryButton: {
        text: t("CancelSubscriptionFailedModalSecondaryButton", { ns: "Modal" }),
        callBack: async () => {
          await store.dispatch(fetchSubscription());
          closeModal();
          ChannelService.openChat();
        },
      },
    };
    showModal(modalProps);
  }
};
