// auth 2.0
import React, { memo } from "react";
import styles from "./DocumentListView.module.scss";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sweetalert from "sweetalert2";
import ListTags from "./listView/ListTags";
import { alertCreatingNewFolder, alertEnterNewFolderName } from "components/alerts";
import { Typography15, Icon } from "@slid/slid-ips";
import { useAppDispatch, useAppSelector } from "hooks";
import { setDocument, setIsListOpen, setShowSearchPopup, createFolder, fetchDocuments, setShowDeletedDocumentsPopup } from "redux/actions/vdocsActions";
import { useMediaQuery } from "react-responsive";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { setShowSearchModal } from "redux/actions/searchActions";

const DocumentListView = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const { applicationType, lang } = useAppSelector((state) => state.slidGlobal);
  const { currentDocument, editorWrapperClassName, documents, iframePort } = useAppSelector((state) => state.vdocs);
  const isVideoNote = window.location.pathname.includes("vdocs");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const openNewFolderPopup = () => {
    alertEnterNewFolderName({ target: isMobile ? "body" : `.${editorWrapperClassName}` }).then(async (result) => {
      if (result.isConfirmed) {
        alertCreatingNewFolder({ target: isMobile ? "body" : `.${editorWrapperClassName}` });
        const createdFolder = await dispatch(
          createFolder({
            title: result.value,
            parentKey: "root",
          })
        );
        if (createdFolder.error_message) {
          // Handle if necessary
        }
        dispatch(fetchDocuments());
        Sweetalert.close();
      }
    });
  };

  const launchMyDocsWindowInElectron = () => {
    /*
    Sends messages to the primary window of Slid Desktop to launch my-docs
    */
    if (iframePort) {
      iframePort.postMessage({
        type: "IFRAME_TO_PRIMARY_OPEN_MY_DOCS",
        payload: null,
      });
    } else {
      history.push(`/docs`);
    }
    //return false to prevent <a> tag defaulf onClick behavior
    return false;
  };

  return (
    <div className={styles[`list-view-container`]}>
      <>
        <div className={styles[`home-btn-container`]}>
          {applicationType === "extension" ? (
            // in extension we want to open docs in a new tab
            <a href={`/docs`} target={`_blank`}>
              <Typography15 weight={700} color="--gray17" text={t("MyDocs", { ns: "common" })} style={{ color: "#000" }} />
            </a>
          ) : // in desktop we want to launch docs in a new window
          applicationType === "desktop" ? (
            <a href="/#" onClick={() => launchMyDocsWindowInElectron()}>
              <Typography15 weight={700} color="--gray17" text={t("MyDocs", { ns: "common" })} style={{ color: "#000" }} />
            </a>
          ) : (
            <Link to={`/docs`}>
              <Typography15 weight={700} color="--gray17" text={t("MyDocs", { ns: "common" })} style={{ color: "#000" }} />
            </Link>
          )}
          <img
            src={`/src/design/assets/slid_close_btn.png`}
            className={styles[`close-btn`]}
            onClick={() => {
              dispatch(setIsListOpen(false));
            }}
            alt={`close`}
            width={24}
            hegiht={24}
            style={{ cursor: "pointer" }}
          />
        </div>
      </>
      <div className={styles[`docs-list-container`]}>
        <div className={styles[`docs-list-title`]}>
          <span>{lang === "ko" ? "저장된 노트" : "Saved Notes"}</span>
          <img
            className={styles[`search-icon`]}
            src={`/src/design/assets/slid_search_icon.png`}
            alt={``}
            onClick={() => {
              if (isVideoNote) {
                dispatch(setShowSearchPopup(true));
              } else {
                dispatch(setShowSearchModal(true));
              }
            }}
          />
        </div>
        <div className={styles[`docs-list`]}>{documents ? <ListTags /> : "loading..."}</div>
      </div>
      <div className={styles[`docs-list-button-container`]}>
        <div
          className={styles[`trash-can-container`]}
          onClick={() => {
            dispatch(setShowDeletedDocumentsPopup(true));
          }}
        >
          <div className={styles["trash-can-body"]}>
            <div className={styles["trash-can-icon"]}>
              <Icon icon={`delete20`} color={`--gray9`} width="24" height="24" />
            </div>
            <div className={styles["trash-can-text"]}>{lang === "ko" ? "삭제된 노트" : "Deleted Notes"}</div>
          </div>
        </div>
        <div className={styles[`item-add-btn-container`]}>
          <div
            className={styles[`item-add-btn`]}
            onClick={() => {
              if (!confirmPrivilege(SlidFeatures.noteCreation)) return showInsufficientPrivilegeModal();
              if (currentDocument) {
                if (isVideoNote && currentDocument?.mapped_videos.length > 0) {
                  dispatch(
                    setDocument({
                      document_key: "new",
                      mapped_videos: currentDocument?.mapped_videos,
                    })
                  );
                } else {
                  dispatch(setDocument(null));
                }
              }
              history.push(`./new`);
              if (isVideoNote) dispatch(setIsListOpen(false));
            }}
            slid-cy={`editor-navigation-create-document-btn`}
          >
            + New
            <div className={styles[`item-icon`]}>
              <Icon icon={`recentNote20`} color={`--gray9`} width="24" height="24" />
            </div>
          </div>
          <div
            className={styles[`item-add-btn`]}
            onClick={() => {
              if (!confirmPrivilege(SlidFeatures.folderCreation)) return showInsufficientPrivilegeModal();
              openNewFolderPopup();
            }}
          >
            + New
            <div className={styles[`item-icon`]}>
              <Icon icon={`filledFolder24`} color={`--gray9`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default DocumentListView;
