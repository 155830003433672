import { Typography17, Typography15, Typography13, Typography11, Typography24 } from "@slid/slid-ips";
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import styled from "styled-components";
import {
  createDocument,
  registerClip,
  setCroppingImageBlockData,
  setEditImageCropArea,
  setImageCropArea,
  setImageCroppingModalImageSource,
  setIsImageCroppingModalOpen,
  setShouldUpdateImageByCrop,
  setTabletDefaultCropArea,
} from "redux/actions/vdocsActions";
import { uploadBase64Image } from "utils/aws/s3Interface";
import { useTranslation } from "react-i18next";
import { isTouchDevice } from "utils/utils";
import "react-advanced-cropper/dist/style.css";
import { Cropper, ImageRestriction } from "react-advanced-cropper";
import LoadingScreenContent from "../editorLoadingStates/LoadingScreenContent";
import { trackEvent } from "utils/eventTracking";
import { isPreviousBlockEmpty } from "utils/editor/util";

const TabletImageCroppingModal = ({ setShouldShowTabletCaptureGuideButton }: { setShouldShowTabletCaptureGuideButton: Function }) => {
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const {
    editorInstance,
    currentDocument,
    editorLastActiveBlockPosition,
    imageCropArea,
    tabletDefaultCropArea,
    imageCroppingModalImageSource,
    editImageCropArea,
    shouldUpdateImageByCrop,
    croppingImageBlockData,
  } = useAppSelector((state) => state.vdocs);
  const cropperRef = useRef<any>(null);
  const dummyInputRef = useRef<any>(null);
  const { t } = useTranslation("VideoNote");

  useEffect(() => {
    // register keydown event and set image size when it's mounted
    const onKeyDown = (e: any) => {
      switch (e.key) {
        case " ":
          e.preventDefault();
          e.stopPropagation();
          closeModal();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", onKeyDown);

    // focus-out current input cursor on touch devices:
    if (isTouchDevice()) {
      (document.activeElement as HTMLElement)?.blur();
    }

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (dummyInputRef.current) {
      dummyInputRef.current.focus();
    }
  }, [dummyInputRef.current]);

  const closeModal = () => {
    dispatch(setEditImageCropArea(false));
    dispatch(setImageCroppingModalImageSource(null));
    dispatch(setIsImageCroppingModalOpen(false));
    dispatch(setShouldUpdateImageByCrop(null));
    dispatch(setCroppingImageBlockData(null));
    dispatch(setImageCropArea(null));
  };

  const setCropArea = () => {
    const imageElement: any = cropperRef?.current;
    const cropDataResponse = imageElement.getCoordinates();
    const cropData: Object = { width: Math.floor(cropDataResponse.width), height: Math.floor(cropDataResponse.height), x: Math.floor(cropDataResponse.left), y: Math.floor(cropDataResponse.top) };
    //@ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "WEB_TO_APP_sendCropData",
        payload: JSON.stringify(cropData),
      })
    );
    dispatch(setTabletDefaultCropArea(cropData));
    onSaveCroppedImage();
  };

  const onSaveCroppedImage = async () => {
    const imageElement: any = cropperRef?.current;
    const imageURL = imageElement.getCanvas().toDataURL();
    const cropDataResponse = imageElement.getCoordinates();
    const cropData: { x: number; y: number; width: number; height: number } = {
      width: Math.floor(cropDataResponse.width),
      height: Math.floor(cropDataResponse.height),
      x: Math.floor(cropDataResponse.left),
      y: Math.floor(cropDataResponse.top),
    };

    let currentDocumentKey: string;

    if (currentDocument) {
      currentDocumentKey = currentDocument["document_key"];
    } else {
      const createdDocument: any = await dispatch(
        createDocument({
          origin: window.location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs",
        })
      );
      //@ts-ignore
      if (createDocument.error_message) return;
      currentDocumentKey = createdDocument["document_key"];
    }
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: imageURL,
    });
    if (!uploadedImageSrc) return;

    setShouldShowTabletCaptureGuideButton(false);
    closeModal();
    if (shouldUpdateImageByCrop) {
      editorInstance.blocks.update(croppingImageBlockData.id, {
        ...croppingImageBlockData,
        src: uploadedImageSrc,
        fullSizeImageSrcForCropping: imageCroppingModalImageSource,
      });
      return;
    }

    const imageInsertIndex = editorInstance.blocks.getCurrentBlockIndex() === -1 ? editorLastActiveBlockPosition + 1 : undefined;
    setTimeout(() => {
      editorInstance.blocks.insert(
        "image",
        {
          documentKey: currentDocumentKey,
          src: `/src/design/assets/slid_captured_image_loading_${lang === "ko" ? "ko" : "en"}.png`,
          type: "manualCapture",
        },

        undefined,
        imageInsertIndex,
        true
      );

      const previousBlockIndex = editorInstance.blocks.getCurrentBlockIndex() - 1;
      if (isPreviousBlockEmpty(previousBlockIndex)) {
        try {
          editorInstance.blocks.deleteBlock(previousBlockIndex);
        } catch (e) {
          // when focus is lost,
          // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
          // will happen.
          // ignore this for now. it's editor js error.
          // https://github.com/codex-team/editor.js/pull/1218
        }
      }
      // editorInstance.caret.setToNextBlock();
      editorInstance.blocks.insert();
      editorInstance.caret.setToBlock(imageInsertIndex);
      if (editorInstance.blocks.getBlockByIndex(imageInsertIndex)) {
        editorInstance.blocks.getBlockByIndex(imageInsertIndex - 1).holder.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    });

    const clipRegisterResponse: any = await dispatch(
      registerClip({
        imgSrc: uploadedImageSrc,
        documentKey: currentDocumentKey,
        clipTimestamp: 0,
        videoKey: null,
        clipEndPoint: "capture-clip",
      })
    );
    if (clipRegisterResponse.error_message) return;
    dispatch(setImageCropArea(cropData));

    const editorContent = await editorInstance.save();
    await editorInstance.blocks.update(editorContent.blocks[imageInsertIndex].id, {
      ...croppingImageBlockData,
      clipKey: clipRegisterResponse["clip_key"],
      documentKey: currentDocumentKey,
      src: uploadedImageSrc,
      fullSizeImageSrcForCropping: imageCroppingModalImageSource,
      type: "manualCapture",
    });
  };

  return (
    <ModalLayer>
      {/* Dummy input for space shortcut feature of CloseButton */}
      <input ref={dummyInputRef} readOnly style={{ height: "0px", opacity: "0" }} />
      <MobileVersionLabel>
        <MobileVersionText>{`v1.0.0`}</MobileVersionText>
      </MobileVersionLabel>
      {!editImageCropArea && (
        <CloseButton
          onClick={() => {
            trackEvent({ eventType: "Click CLOSE in capture area resizing", eventProperties: { from: "crop image" } });
            closeModal();
          }}
        >
          <CloseButtonText text={t("Close", { ns: "EditorComponent" })} color={`--gray1`} />
        </CloseButton>
      )}
      {imageCroppingModalImageSource ? (
        imageCroppingModalImageSource === "loading" ? (
          <ImageLoadingContainer>
            <LoadingScreenContent loadingText={t("ImageCroppingModalLoadingText")} textColor="--gray3" />
          </ImageLoadingContainer>
        ) : (
          <>
            <VideoLocationUpdateMessage weight={400} color="--gray7" text={t("ImageCroppingModalVideoLocationUpdateMessage")} />

            <ImageCroppingModalContainer>
              <Cropper
                src={imageCroppingModalImageSource}
                stencilProps={{
                  handlers: {
                    eastNorth: true,
                    north: true,
                    westNorth: true,
                    west: true,
                    westSouth: true,
                    south: true,
                    eastSouth: true,
                    east: true,
                  },
                }}
                className=""
                ref={cropperRef}
                defaultCoordinates={{
                  left: tabletDefaultCropArea ? tabletDefaultCropArea.x : 0,
                  top: tabletDefaultCropArea ? tabletDefaultCropArea.y : 0,
                  width: tabletDefaultCropArea ? tabletDefaultCropArea.width : window.innerWidth,
                  height: tabletDefaultCropArea ? tabletDefaultCropArea.height : (9 / 16) * window.innerWidth,
                }}
                style={{ maxWidth: "80%" }}
                imageRestriction={ImageRestriction.fitArea}
                onError={(e) => {
                  // NOTE : When CORS error happens, we need to reload the image.
                  dispatch(setImageCroppingModalImageSource(`${imageCroppingModalImageSource}?cache=${Date.now().toString()}`));
                }}
              />
            </ImageCroppingModalContainer>
          </>
        )
      ) : (
        <EditCropAreaMessageContainer>
          <Typography17 weight={400} color="--gray1" text={t("ImageCroppingModalResettingAreaSubDesc")} />
          <Typography24 weight={400} color={"--gray1"} text={t("ImageCroppingModalResettingAreaDesc1")} marginTop={"32px"} />
          <Typography24 weight={700} color={"--blue6"} text={t("ImageCroppingModalResettingAreaDesc2")} />

          <ShortcutTipContainer>
            <Typography17 text={t("TabletCaptureShortcutText") + " "} color={`--blue2`} weight={700} />
            <ShortcutTextBackground>
              <Typography13 text="Cmd" color={`--white`} weight={700} />
              <Typography13 text=" / " color="--blue7" weight={700} />
              <Typography13 text="Alt" color={`--white`} weight={700} />
            </ShortcutTextBackground>
            <Typography11 text="+" color="--blue2" weight={400} />
            <ShortcutText text="Shift" color={`--white`} weight={700} />
            <Typography11 text="+" color="--blue2" weight={400} />
            <ShortcutText text="3" color={`--white`} weight={700} />
          </ShortcutTipContainer>
        </EditCropAreaMessageContainer>
      )}

      <ButtonContainer>
        {imageCroppingModalImageSource ? (
          <>
            {editImageCropArea ? (
              <ButtonTextContainer onClick={() => closeModal()}>
                <Typography15 weight={700} color="--gray3" text={t("ImageCroppingModalResettingCancel")} />
              </ButtonTextContainer>
            ) : (
              <ButtonTextContainer
                onClick={() => {
                  trackEvent({
                    eventType: "Click APPLY ONCE in capture area resizing",
                    eventProperties: {
                      from: shouldUpdateImageByCrop ? "crop image" : "1-click capture",
                    },
                  });
                  onSaveCroppedImage();
                }}
              >
                <Typography15 weight={700} color="--gray3" text={t("ImageCroppingModalApplyToCurrentOnly")} />
              </ButtonTextContainer>
            )}
            <VerticalLine></VerticalLine>
            <ButtonTextContainer
              onClick={() => {
                trackEvent({
                  eventType: "Click APPLY CONTINUOUSLY in capture area resizing",
                  eventProperties: {
                    from: editImageCropArea ? "reset capture area" : shouldUpdateImageByCrop ? "crop image" : "1-click capture",
                  },
                });
                setCropArea();
              }}
            >
              {!editImageCropArea && !shouldUpdateImageByCrop && (
                <TabletCaptureGuideToolTip>
                  <Typography11 weight={400} color="--gray1" text={t("ImageCroppingModalCaptureGuideTooltip")} textAlign="center" />
                </TabletCaptureGuideToolTip>
              )}
              <Typography15 weight={700} color="--blue7" text={t("ImageCroppingModalApplyNextAutomatically")} />
            </ButtonTextContainer>
          </>
        ) : (
          <ButtonTextContainer
            onClick={() => {
              trackEvent({ eventType: "Click CANCEL in capture area resizing", eventProperties: { from: "reset capture area" } });
              closeModal();
            }}
          >
            <Typography15 weight={700} color="--gray3" text={t("ImageCroppingModalResettingBack")} />
          </ButtonTextContainer>
        )}
      </ButtonContainer>
    </ModalLayer>
  );
};

export default TabletImageCroppingModal;

const ModalLayer = styled.div<{ isMobile?: boolean }>`
  position: ${(props) => (props.isMobile ? "fixed" : "absolute")};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 13, 14, 0.95);
  backdrop-filter: blur(4px);
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobileVersionLabel = styled.div`
  position: fixed;
  top: 20px;
  left: 12px;
  padding: 4px 6px;
  background-color: var(--gray17);
  border-radius: 6px;
  z-index: 99999;
`;

const MobileVersionText = styled.p`
  margin: 1px 0 0 0;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1.6rem;
  color: var(--gray9);
`;

const ImageLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const VideoLocationUpdateMessage = styled(Typography11)`
  && {
    position: relative;
    padding: 0 15px;
    top: 44px;

    border-radius: 4px;
    color: white;
    z-index: 999999;
    word-break: keep-all;
    text-align: center;
  }
`;

const ImageCroppingModalContainer = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0px 32px;
  margin: 12px 0px 52px 0px;
`;

const CloseButton = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */

  background: #000;

  &:hover {
    background: var(--gray15);
  }

  position: fixed;
  height: 40px;
  padding: 0 15px;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  color: white;
  z-index: 999999;
`;

const CloseButtonText = styled(Typography15)`
  && {
    z-index: 999999;
  }
`;

const EditCropAreaMessageContainer = styled(ImageCroppingModalContainer)`
  gap: 0px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: var(--gray17);
  border-top: 1px solid var(--gray13);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ButtonTextContainer = styled.div`
  width: auto;
  height: auto;
  padding: 12px 20px;
  cursor: pointer;

  :active {
    border-radius: 8px;
    background-color: var(--gray15);
  }
`;

const VerticalLine = styled.div`
  height: 24px;
  border-right: 1px solid var(--gray9);
`;

const TabletCaptureGuideToolTip = styled.div`
  position: absolute;
  bottom: 78px;
  width: auto;
  height: 48px;
  background-color: var(--gray15);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  padding: 8px 12px;

  ::before {
    display: block;
    content: "";
    position: absolute;
    bottom: -6px;
    left: 45%;
    width: 0px;
    height: 0px;
    border-top: 10px solid none;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--gray15);
    transform: rotate(0.5turn);
  }
`;

const ShortcutText = styled(Typography13)`
  && {
    background-color: var(--blue11);
    padding: 2px 6px;
    border-radius: 4px;
  }
`;
const ShortcutTextBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue11);
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 4px;
`;

const ShortcutTipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 32px;
`;
