import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import SelectLanguageView from "./SelectLanguageView";
import AutoNotesSettingsViewHeader from "./AutoNotesSideBarHeader";
import { setShowSmartLiveTextView } from "redux/actions/sttActions";
import ExtensionPermissionGuide from "./ExtensionPermisionGuide";
import useWhisperSLTStore from "store/useWhisperSLTStore";

const AutoNotesSideBarView = memo(() => {
  const { isAutoNotesSettingsOpen } = useAppSelector((state) => state.autoNotes);
  const dispatch = useAppDispatch();

  const {setShowSmartLiveTextView: setShowWhisperSmartLiveTextView} = useWhisperSLTStore()

  useEffect(() => {
    if (isAutoNotesSettingsOpen) {
      dispatch(setShowSmartLiveTextView(false));
      setShowWhisperSmartLiveTextView(false)
    }
  }, [isAutoNotesSettingsOpen, dispatch]);

  return (
    <SettingsContainer showAutoNotesSettings={isAutoNotesSettingsOpen}>
      {isAutoNotesSettingsOpen && (
        <>
          <AutoNotesSettingsViewHeader />
          <SelectLanguageView />
          <ExtensionPermissionGuide />
        </>
      )}
    </SettingsContainer>
  );
});

export default AutoNotesSideBarView;

const SettingsContainer = styled.div<{ showAutoNotesSettings: boolean }>`
  border-left: ${({ showAutoNotesSettings }) => (showAutoNotesSettings ? "1px solid var(--gray17)" : "0")};
  height: 100%;
  max-width: 400px;
  min-width: ${({ showAutoNotesSettings }) => (showAutoNotesSettings ? "400px" : "0")};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: var(--gray19);
  * {
    &::selection {
      background: rgba(27, 100, 218, 0.6) !important;
    }
  }
`;
