// auth 2.0
import { convertReceivedDataIntoObjectType } from "../utils";

export const setChromeExtentionVersionResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_checkExtensionVersion":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setChromeExtentionVersionResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setVideoInfoResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_getVideoInfo":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setVideoInfoResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setVideoMetadataResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_getVideoMetadata":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setVideoInfoResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setVideoTimestampResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_getCurrentVideoTimestamp":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setVideoInfoResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const LEGACY_setVideoInfoResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_getVideoRect":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("LEGACY_setVideoInfoResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

// legacy
export const setScreenCaptureResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_sendCaptureImg":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setScreenCaptureResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setOneClickCaptureResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_sendCaptureImg":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setOneClickCaptureResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setSetCurrentCaptureAreaResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_setCurrentCaptureArea":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSetCurrentCaptureAreaResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setVideoControlListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_playedVideo":
            responseHandler({
              type: "updatePlay",
              value: true,
            });
            return;
          case "CONTENT_TO_IFRAME_pausedVideo":
            responseHandler({
              type: "updatePlay",
              value: false,
            });
            return;
          case "CONTENT_TO_IFRAME_changePlaybackRate":
            responseHandler({
              type: "updatePlaybackRate",
              value: receivedData.data.value,
            });
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setVideoControlListener message parsing error");
        console.error(e);
      }
    },
  });
};

const setMessageListenerFromParentContent = ({ messageCallback }) => {
  window.addEventListener("message", (message) => {
    messageCallback(message);
  });
  return;
};

export const setSTTDataResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;
        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_receiveAudioTranscriptResult":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSTTDataResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setWhisperResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;
        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_sendFinalTextToWhisperResult":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setWhisperResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setTranscriptResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;
        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_sendTranscriptResult":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setWhisperResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setWhisperFailedListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;
        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_sendWhisperFailed":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setWhisperResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setSTTErrorListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_receiveAudioTranscriptError":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSTTErrorListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setAutoNotesSTTDataResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;
        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_receiveAutoNotesSTTResult":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setAutoNotesSTTDataResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setAutoNotesSTTErrorListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_receiveAutoNotesSTTError":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSTTErrorListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setSTTSocketConnectionStatusListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_setSTTSocketConnectionStatus":
            responseHandler(receivedData.data); //{isSTTSocketConnected: true | false}
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSTTSocketConnectionStatusListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setCheckIsExtensionMediaPermittedResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_setIsExtensionMediaPermitted":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setCheckIsExtensionMediaPermittedResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setSetVideoArrayBufferResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;
        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_sendVideoArrayBuffer":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSetVideoArrayBufferResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setSnipCaptureIsDoneResponseListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_snipCaptureIsDone":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setSnipCaptureIsDoneResponseListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setCheckIsSlidInstantLaunchedListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_checkIsSlidInstantLaunched":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setCheckIsSlidInstantLaunchedListener message parsing error");
        console.error(e);
      }
    },
  });
};

export const setIsExtensionClickedListener = ({ responseHandler }) => {
  setMessageListenerFromParentContent({
    messageCallback: (message) => {
      try {
        const receivedData = convertReceivedDataIntoObjectType(message.data);
        if (!receivedData) return;

        switch (receivedData.action) {
          case "CONTENT_TO_IFRAME_EXTENSION_CLICKED":
            responseHandler(receivedData.data);
            return;
          default:
            return;
        }
      } catch (e) {
        // ignore
        console.log("setIsExtensionClickedListener message parsing error");
        console.error(e);
      }
    },
  });
};
