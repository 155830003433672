// auth 2.0

import {
  SET_LANG,
  SET_USER_DATA,
  SET_APPLICATION_TYPE,
  SET_EXTENSION_VERSION,
  SET_DESKTOP_VERSION,
  SET_ERROR_MESSAGE,
  SET_SCREEN_ORIENTATION,
  FETCH_NOTIFICATIONS,
  SET_IS_EXTENSION_INSTALLED,
  SET_NOTIFICATION_DATA,
  SET_EXTENSION_ID,
  SET_IS_NEW_USER,
  SET_GRACE_PERIODS,
  SET_IS_ONBOARDING_NEEDED_USER,
  SET_USER_TRIAL_DATA,
  SET_HACKLE_EXPERIMENT_KEYS,
  SET_IS_TABLET_APP_OPENED_IN_SPLIT_VIEW,
  SET_IS_USER_CAPTURED_BY_TABLET_BEFORE,
  SET_USER_TABLET_DEVICE_INFO,
  SET_TABLET_ORIENTATION,
  SET_PENDING_TRACK_EVENT,
  CLEAR_PENDING_TRACK_EVENTS,
  SET_USER_STUDY_CHALLENGE_DATA,
  SET_IS_SIGNING_UP,
  SET_IS_SIGNING_IN,
} from "./actionTypes";
import { ServerInterface_createUser, ServerInterface_fetchUser, ServerInterface_updateUser, ServerInterface_deleteAccount } from "api/usersApi";
import { ServerInterface_isSlidUser } from "api/userExistsApi";
import { ServerInterface_getUserTrialData } from "api/trialApi";
import { ServerInterface_fetchGracePeriods } from "api/gracePeriodApi";
import { ServerInterface_getNotifications, ServerInterface_markNotificationsAsViewed } from "api/notificationsApi";
import { ServerInterface_slidExpertAccountToggle } from "api/slidExpertApi";
import { ServerInterface_fetchStudyChallengeData, ServerInterface_patchStudyChallengeData } from "api/studyChallengeApi";

import env from "config/env";
import store from "redux/store";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";
import * as Sentry from "@sentry/browser";
const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

export const setLang = (lang) => (dispatch) => {
  dispatch({
    type: SET_LANG,
    payload: lang,
  });
};

export const fetchUser = () => async (dispatch) => {
  const userData = await ServerInterface_fetchUser();
  if (userData["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: userData["error_message"],
    });
    return userData;
  }
  dispatch(setUserData(userData));
  try {
    const { is_new_user: isNewUser } = JSON.parse(userData.more_info) || {};
    if (isNewUser) dispatch(setIsNewUser(true));
    else dispatch(setIsNewUser(false));
  } catch (err) {
    console.error(err);
  }
  return userData;
};

export const fetchGracePeriods = () => async (dispatch) => {
  const gracePeriods = await ServerInterface_fetchGracePeriods();
  if (gracePeriods["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: gracePeriods["error_message"],
    });
    return;
  }
  dispatch({
    type: SET_GRACE_PERIODS,
    payload: gracePeriods?.grace_periods || [],
  });
};

export const isSlidUser = () => async () => {
  const cognitoIdToken = await getUserIdToken();
  const isSlidUser = await ServerInterface_isSlidUser({ cognitoIdToken });
  //NOTE: No need to handle the errors, it simply checks if a user in our db during auth
  return isSlidUser;
};

export const createUser =
  ({ loginType, locale, userCountry }) =>
  async (dispatch) => {
    const userData = await ServerInterface_createUser({
      loginType,
      locale,
      userCountry,
    });
    if (userData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: userData["error_message"],
      });
      return userData;
    }
    dispatch(setUserData(userData));
    return userData;
  };

export const setUserData = (userData) => (dispatch) => {
  dispatch({
    type: SET_USER_DATA,
    payload: userData,
  });
};

export const fetchNotifications = () => async (dispatch) => {
  const notificationsResponse = await ServerInterface_getNotifications();
  if (notificationsResponse.error_message) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: notificationsResponse.error_message,
    });
    return notificationsResponse;
  }
  dispatch({
    type: FETCH_NOTIFICATIONS,
    payload: notificationsResponse.notifications,
  });
  return notificationsResponse.notifications;
};

export const markNotificationsAsViewed =
  ({ notificationsData }) =>
  async (dispatch) => {
    const { error_message } = await ServerInterface_markNotificationsAsViewed({ notificationsData });
    if (error_message) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: error_message,
      });
    }
    await dispatch(fetchNotifications());
  };

export const setApplicationType = (applicationType) => async (dispatch) => {
  dispatch({
    type: SET_APPLICATION_TYPE,
    payload: applicationType,
  });
};

export const setExtensionVersion = (extensionVersion) => (dispatch) => {
  dispatch({
    type: SET_EXTENSION_VERSION,
    payload: extensionVersion,
  });
};

export const setDesktopVersion = (desktopVersion) => (dispatch) => {
  dispatch({
    type: SET_DESKTOP_VERSION,
    payload: desktopVersion,
  });
};

export const setErrorMessage = (errorMessage) => (dispatch) => {
  dispatch({
    type: SET_ERROR_MESSAGE,
    payload: errorMessage,
  });
};

export const setScreenOrientation = (screenOrientation) => (dispatch) => {
  dispatch({
    type: SET_SCREEN_ORIENTATION,
    payload: screenOrientation,
  });
};

export const setIsExtensionInstalled = (isExtensionInstalled) => (dispatch) => {
  dispatch({
    type: SET_IS_EXTENSION_INSTALLED,
    payload: isExtensionInstalled,
  });
};

export const setNotificationData = (notificationData) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION_DATA,
    payload: notificationData,
  });
};

export const setExtensionId =
  (id = null) =>
  (dispatch) => {
    // For extension - iframe
    if (id) {
      dispatch({
        type: SET_EXTENSION_ID,
        payload: id,
      });
      return;
    }
    // For web - extension
    window.addEventListener("message", (e) => {
      if (e.origin === `${SLID_WEB_APP_URL}`) {
        if (e.data.action === "INJECTED_CONTENT_TO_WEB_getExtensionId") {
          dispatch({
            type: SET_EXTENSION_ID,
            payload: e.data.data,
          });
          dispatch(setIsExtensionInstalled(true));
        }
      }
    });
  };

export const setIsNewUser = (isNewUser) => (dispatch) => {
  dispatch({
    type: SET_IS_NEW_USER,
    payload: isNewUser,
  });
};

export const updateUser =
  ({ data }) =>
  async (dispatch) => {
    const updatedUserData = await ServerInterface_updateUser({ data });
    if (updatedUserData.error_message) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: updatedUserData.error_message,
      });
      return;
    }
    dispatch(setUserData(updatedUserData));
  };

export const deleteAccount = () => async (dispatch) => {
  const result = await ServerInterface_deleteAccount();
  if (result.error_message) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: result.error_message,
    });
    return;
  }
};

export const setIsOnboardingNeededUser = (isOnboardingNeededUser) => (dispatch) => {
  dispatch({
    type: SET_IS_ONBOARDING_NEEDED_USER,
    payload: isOnboardingNeededUser,
  });
};

export const setUserTrialData = () => async (dispatch) => {
  const cognitoIdToken = await getUserIdToken();
  if (!cognitoIdToken) {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage("Tried to fetch user data without cognito token");
    });
    return;
  }
  const result = await ServerInterface_getUserTrialData({ cognitoIdToken });
  if (result.error_message) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: result.error_message,
    });
    return;
  }
  dispatch({
    type: SET_USER_TRIAL_DATA,
    payload: result,
  });
};

export const setHackleExperimentKeys = (experimentKeys) => (dispatch) => {
  dispatch({
    type: SET_HACKLE_EXPERIMENT_KEYS,
    payload: experimentKeys,
  });
};

export const setIsTabletAppOpenedInSplitView = (isTabletAppOpenedInSplitView) => (dispatch) => {
  dispatch({
    type: SET_IS_TABLET_APP_OPENED_IN_SPLIT_VIEW,
    payload: isTabletAppOpenedInSplitView,
  });
};

export const setIsUserCapturedByTabletBefore = (isUserCapturedByTabletBefore) => (dispatch) => {
  dispatch({
    type: SET_IS_USER_CAPTURED_BY_TABLET_BEFORE,
    payload: isUserCapturedByTabletBefore,
  });
};

export const setUserTabletDeviceInfo = (userTabletDeviceInfo) => (dispatch) => {
  dispatch({
    type: SET_USER_TABLET_DEVICE_INFO,
    payload: userTabletDeviceInfo,
  });
};

export const setTabletOrientation = (tabletOrientation) => (dispatch) => {
  dispatch({
    type: SET_TABLET_ORIENTATION,
    payload: tabletOrientation,
  });
};

export const setPendingTrackEvent = (pendingTrackEvent) => (dispatch) => {
  dispatch({
    type: SET_PENDING_TRACK_EVENT,
    payload: pendingTrackEvent,
  });
};

export const clearPendingTrackEvents = () => (dispatch) => {
  dispatch({
    type: CLEAR_PENDING_TRACK_EVENTS,
  });
};

export const slidExpertAccountToggle = (accountType) => async (dispatch) => {
  const { lang } = store.getState().slidGlobal;
  const result = await ServerInterface_slidExpertAccountToggle({ accountType });
  if (result.error_message) {
    if (lang === "ko") alert("변경 권한을 확인 후 다시 시도하세요.");
    else alert("Could not toggle the account, please check your permission and try again.");
    return;
  } else {
    if (lang === "ko") alert("회원권이 성공적으로 변경됐습니다.");
    else alert("Account toggled successfully!");
  }
};

/**
 * Server
 * @returns
 */
// UserStudyChallengeDataItemType {
//   id: number;
//   created_at: string; // ISO 8601 format
//   updated_at: string; // ISO 8601 format
//   user_email: string;
//   challenge_date: string; // YYYY-MM-DD
//   challenge_day: number;
//   total_study_time: number; // seconds
//   study_goal_accomplished: boolean;
//   reminder_email_sent: boolean;
//   reminder_kakao_sent: boolean;
//   event: number;
// }

//  UserStudyChallengeDataType {
//   event_name: "14_DAYS_STUDY_CHALLENGE";
//   start_date: string; // ISO 8601 format
//   end_date: string; // ISO 8601 format
//   is_active: boolean;
//   total_days: number;
//   daily_study_goal: number;
//   progress: [UserStudyChallengeDataItemType];
// }

/**
 * information needed:
 * 1. originial data
 * 2. passed failed not set data -> progressMarker
 */

export const fetchStudyChallengeData = () => async (dispatch) => {
  const data = {};
  let fetchedData = await ServerInterface_fetchStudyChallengeData({ data });
  let studyChallengeData = fetchedData[0];

  if (!studyChallengeData || fetchedData.error_message) {
    dispatch({
      type: SET_USER_STUDY_CHALLENGE_DATA,
      payload: null,
    });
    return;
  }

  let _startDate = new Date(studyChallengeData?.progress[0]?.challenge_date);
  _startDate.setHours(0, 0, 0, 0);
  let _now = new Date();
  _now.setHours(0, 0, 0, 0);
  const currentDay = Math.ceil((_now - _startDate) / (1000 * 60 * 60 * 24)) + 1;

  let progressMarkers = [];
  for (const progressItem of studyChallengeData.progress) {
    // if the challenge day is less than current day, then it is either passed or failed
    if (progressItem.challenge_day < currentDay) {
      progressMarkers.push({
        challengeDay: progressItem.challenge_day,
        totalSeconds: progressItem.total_study_time,
        status: progressItem.study_goal_accomplished ? "PASSED" : "FAILED",
      });
    } else if (progressItem.challenge_day === currentDay) {
      progressMarkers.push({
        challengeDay: progressItem.challenge_day,
        totalSeconds: progressItem.total_study_time,
        status: progressItem.study_goal_accomplished ? "PASSED" : "NOT_YET",
      });
    } else {
      progressMarkers.push({
        challengeDay: progressItem.challenge_day,
        totalSeconds: progressItem.total_study_time,
        status: "NOT_YET",
      });
    }
  }

  const userStudyChallengeData = {
    isActive: studyChallengeData.is_active,
    totalDays: studyChallengeData.total_days,
    dailyStudyGoal: studyChallengeData.daily_study_goal, // minutes
    progressMarkers: progressMarkers,
    startDate: new Date(studyChallengeData.start_date),
    currentDate: new Date().toLocaleDateString("pt-br").split("/").reverse().join("-"),
    currentDay: currentDay,
    currentProgressMarker: progressMarkers.find((item) => item.challengeDay === currentDay) || null,
    passed: studyChallengeData.passed,
  };

  dispatch({
    type: SET_USER_STUDY_CHALLENGE_DATA,
    payload: userStudyChallengeData,
  });
};

export const patchStudyChallengeData =
  ({ data }) =>
  async (dispatch) => {
    const { userStudyChallengeData } = store.getState().slidGlobal;
    const updatedItem = await ServerInterface_patchStudyChallengeData({ data });
    const currentProgressMarker = {
      challengeDay: updatedItem.challenge_day,
      totalSeconds: updatedItem.total_study_time,
      status: updatedItem.study_goal_accomplished ? "PASSED" : "FAILED",
    };

    dispatch({
      type: SET_USER_STUDY_CHALLENGE_DATA,
      payload: {
        ...userStudyChallengeData,
        progressMarkers: [...userStudyChallengeData.progressMarkers].map((item) => (item.challengeDay !== currentProgressMarker.challengeDay ? item : currentProgressMarker)),
        currentProgressMarker: currentProgressMarker,
      },
    });

    return userStudyChallengeData;
  };

export const setIsSigningUp = (isSigningUp) => (dispatch) => {
  dispatch({
    type: SET_IS_SIGNING_UP,
    payload: isSigningUp,
  });
};

export const setIsSigningIn = (isSigningIn) => (dispatch) => {
  dispatch({
    type: SET_IS_SIGNING_IN,
    payload: isSigningIn,
  });
};
