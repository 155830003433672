import { ModalProps } from "types/modal";
import { create } from "zustand";

export const ModalType = {
  LOADING: "loading",
  MOVING: "moving",
  STUDY_CHALLENGE: "study_challenge",
  NEW_MOBILE_APP_GUIDE: "new_mobile_app_guide",
};

interface ModalStoreProps {
  modalState: ModalProps;
  showModal: (props: ModalProps) => void;
  hideModal: () => void;
}

export const useModalStore = create<ModalStoreProps>((set) => ({
  modalState: {
    type: "",
    display: false,
    text: "",
  },
  showModal: (props) =>
    set({
      modalState: {
        ...props,
        display: true,
      },
    }),
  hideModal: () =>
    set({
      modalState: {
        type: "",
        display: false,
      },
    }),
}));
