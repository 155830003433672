//auth 2.0
import React, { useEffect, useState } from "react";
import env from "config/env";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { Typography11, Icon, Typography13, useModal, useToast, ToggleButton } from "@slid/slid-ips";
import { setIsEditorOptionOpen, setIsPDFUploaderClicked, setIsSaveDocumentCalled, setShowSharePopup } from "redux/actions/vdocsActions";
import { sendAmplitudeData } from "utils/amplitude";
import { alertEmptyNoteExporting, alertEmptyNoteSharing } from "components/alerts";
import { showCustomModal } from "utils/modal";
import { useHistory } from "react-router-dom";
import { downloadImages, downloadMarkdown, downloadWord, copyNoteToClipboard } from "utils/exportNote";
import { sendMessageToPrimary } from "../../utils/utils";
import { trackEvent } from "utils/eventTracking";
import { isMacOs, isMobile } from "react-device-detect";
import SignupModal from "components/DemoVideoNoteEditor/SignupModal";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { useNoteDownload } from "hooks";
import { SlidFeatures } from "utils/privilegeManager";

interface EditorOptionProps {
  handleOpenRecentNote?: () => void;
  openRecentNote?: boolean;
}

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

const EditorOptionDropdownMenu = ({ handleOpenRecentNote, openRecentNote }: EditorOptionProps) => {
  const { t } = useTranslation(["VideoNote", "SweetAlertModals", "ViewNote"]);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { showModal, closeModal } = useModal();
  const { undoInstance, currentDocument, iframePort, isSTTSupported } = useAppSelector((state) => state.vdocs);
  const { smartLiveTextLanguage } = useAppSelector((state) => state.sttReducer);
  const { applicationType, lang } = useAppSelector((state) => state.slidGlobal);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { downloadCurrentNoteToPDF } = useNoteDownload();

  const [isExportOptionOpened, setIsExportOptionOpened] = useState<boolean>(false);
  const [isDefaultLanguageOn, setIsDefaultLanguageOn] = useState<boolean>(false);

  const isDemoMode = document.location.href === `${SLID_WEB_APP_URL}/demo`;

  useEffect(() => {
    const defaultLang = window.localStorage.getItem("slid-user-stt-default-lang");
    const hasUserSetLang = window.localStorage.getItem("slid-user-has-set-stt-default-lang");

    // if user has set default language, set isDefaultLanguageOn to true
    // Initially set isDefaultLanguageOn to true if user once select language
    if (defaultLang || (!hasUserSetLang && smartLiveTextLanguage)) {
      setIsDefaultLanguageOn(true);
    }
  }, [smartLiveTextLanguage]);

  // NOTE: prints from vid-docs page
  const renderPdfPrint = () => {
    return (
      <RenderPDFOptionContainer
        onClick={async () => {
          await downloadCurrentNoteToPDF();
        }}
      >
        <Icon icon={`pdf16`} color={`--gray9`} width={20} height={20} />
        <Typography13 text={`PDF`} color={`--gray15`} style={{ marginLeft: "4px" }} />
      </RenderPDFOptionContainer>
    );
  };

  return (
    <>
      <DropDownMenuBackground
        onClick={() => {
          dispatch(setIsEditorOptionOpen(false));
        }}
      />
      <DropdownMenuWrapper data-testid={`dropdown-menu-wrapper`}>
        {!isExportOptionOpened ? (
          <>
            <OpenRecentNoteOptionWrapper>
              <OpenRecentNoteButton
                isDemoMode={isDemoMode}
                onClick={() => {
                  handleOpenRecentNote && handleOpenRecentNote();
                }}
              >
                <Typography13 text={t("AlwaysOpenRecentNote", { ns: "VideoNote" })} color={`--gray15`} />
                <OpenRecentNoteToggle>
                  <ToggleControl className="toggle-control">
                    <ToggleInput type="checkbox" checked={openRecentNote} disabled />
                    <ControlButton className="control"></ControlButton>
                  </ToggleControl>
                </OpenRecentNoteToggle>
              </OpenRecentNoteButton>
            </OpenRecentNoteOptionWrapper>
            <ButtonGroupWrapper>
              <UndoRedoButton
                onClick={() => {
                  undoInstance.undo();
                }}
              >
                <ButtonIconTextWrapper>
                  <IconContainer>
                    <Icon icon={`undo20`} color={`--gray9`} width={20} height={20} />
                  </IconContainer>
                  <Typography13 text={t("Undo", { ns: "VideoNote" })} color={`--gray15`} />
                </ButtonIconTextWrapper>
                <ButtonShortcutText text={t("UndoShortcut", { os: isMacOs ? "cmd" : "ctrl" })} color={`--gray7`} />
              </UndoRedoButton>
              <UndoRedoButton
                onClick={() => {
                  undoInstance.redo();
                }}
              >
                <ButtonIconTextWrapper>
                  <IconContainer>
                    <Icon icon={`redo20`} color={`--gray9`} />
                  </IconContainer>
                  <Typography13 text={t("Redo", { ns: "VideoNote" })} color={`--gray15`} />
                </ButtonIconTextWrapper>
                <ButtonShortcutText text={t("RedoShortcut", { os: isMacOs ? "cmd" : "ctrl" })} color={`--gray7`} />
              </UndoRedoButton>
            </ButtonGroupWrapper>
            <ButtonGroupWrapper>
              <ImportPDFButton
                isDemoMode={isDemoMode}
                onClick={async () => {
                  if (!confirmPrivilege(SlidFeatures.pdfUpload)) return showInsufficientPrivilegeModal();
                  dispatch(setIsPDFUploaderClicked(true));
                  dispatch(setIsEditorOptionOpen(false));
                }}
              >
                <IconContainer>
                  <Icon icon={`import20`} color={`--gray9`} />
                </IconContainer>
                <Typography13 text={t("ImportPDF", { ns: "VideoNote" })} color={`--gray15`} />
              </ImportPDFButton>
              <ExportButton
                isDemoMode={isDemoMode}
                onClick={() => {
                  if (currentDocument && currentDocument.content !== "{}") {
                    setIsExportOptionOpened(true);
                    dispatch(setIsSaveDocumentCalled(true));
                  } else {
                    dispatch(setIsEditorOptionOpen(false));
                    alertEmptyNoteExporting({ target: isMobile ? "body" : "#editor-wrapper" });
                    return;
                  }
                }}
              >
                <IconContainer>
                  <Icon icon={`export20`} color={`--gray9`} />
                </IconContainer>
                <ButtonTextContainer>
                  <Typography13 text={t("Export", { ns: "VideoNote" })} color={`--gray15`} />
                  <ExportDesc>PDF, Word, Images, Markdown</ExportDesc>
                </ButtonTextContainer>
                <ExportChevronRightIconContainer>
                  <Icon icon={`chevronRight16`} color={`--gray9`} />
                </ExportChevronRightIconContainer>
              </ExportButton>
            </ButtonGroupWrapper>
            <ButtonGroupWrapper>
              <ShareNoteButton
                isDemoMode={isDemoMode}
                onClick={() => {
                  sendAmplitudeData(`Click share note`, {
                    origin: "video docs",
                  });
                  if (!confirmPrivilege(SlidFeatures.noteSharing)) return showInsufficientPrivilegeModal();
                  if (!currentDocument) {
                    dispatch(setIsEditorOptionOpen(false));
                    alertEmptyNoteSharing({ target: isMobile ? "" : "#editor-wrapper" });
                    return;
                  }
                  dispatch(setIsEditorOptionOpen(false));
                  dispatch(setShowSharePopup(true));
                }}
              >
                <IconContainer>
                  <Icon icon={`share20`} color={`--gray9`} />
                </IconContainer>
                <ButtonTextContainer>
                  <Typography13 text={t("ShareNote", { ns: "VideoNote" })} color={`--gray15`} />
                </ButtonTextContainer>
              </ShareNoteButton>
              <MarkdownCopyButton
                isDemoMode={isDemoMode}
                onClick={() => {
                  if (!confirmPrivilege(SlidFeatures.noteClipboardCopy)) return showInsufficientPrivilegeModal();
                  dispatch(setIsEditorOptionOpen(false));
                  copyNoteToClipboard({ copyType: "clipboard", showToast, target: isMobile ? "" : "#editor-wrapper" });
                }}
              >
                <IconContainer>
                  <Icon icon={`copy20`} color={`--gray9`} />
                </IconContainer>
                <ButtonTextContainer>
                  <Typography13 text={t("VideoNoteCopy", { ns: "VideoNote" })} color={`--gray15`} />
                  <ExportDesc>{t("VideoNoteCopyDesc", { ns: "VideoNote" })}</ExportDesc>
                </ButtonTextContainer>
              </MarkdownCopyButton>
            </ButtonGroupWrapper>

            <MyAccountButtonWrapper isDesktop={applicationType === "desktop"}>
              <MyAccountButton
                onClick={() => {
                  if (isDemoMode) {
                    trackEvent({
                      eventType: "click SIGN-UP in demo",
                      eventProperties: {
                        location: "dropdown menu",
                        platfrom: applicationType,
                      },
                    });
                    if (applicationType === "extension") {
                      showCustomModal({ showModal, closeModal, history, customComponentContent: <SignupModal /> });
                    } else {
                      history.push("/sign-up?from=demo-video-note");
                    }
                  } else {
                    window.open(`/docs?mode=myAccount`);
                  }
                }}
              >
                <IconContainer>
                  <Icon icon={`account20`} color={`--gray9`} />
                </IconContainer>
                <Typography13 text={t(isDemoMode ? "SignUp" : "Account", { ns: "VideoNote" })} color={`--gray15`} />
              </MyAccountButton>
            </MyAccountButtonWrapper>
            {applicationType === "desktop" && (
              <LogoutButtonWrapper>
                <LogoutButton
                  onClick={() => {
                    trackEvent({ eventType: `Click SIGN-OUT in account` });
                    sendMessageToPrimary({
                      message: {
                        type: "IFRAME_TO_PRIMARY_LOG_OUT",
                      },
                      port: iframePort,
                    });
                  }}
                >
                  <IconContainer>
                    <Icon icon={`logout20`} color={`--gray9`} />
                  </IconContainer>
                  <Typography13 text={t("Logout", { ns: "VideoNote" })} color={`--gray15`} />
                </LogoutButton>
              </LogoutButtonWrapper>
            )}
            {(applicationType === "extension" || (applicationType === "desktop" && isSTTSupported)) && (
              <>
                <STTMenuTitle>
                  <Typography11 text={t("SmartLiveTextSetting")} color={`--gray9`} />
                </STTMenuTitle>
                <STTItemWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDefaultLanguageOn(!isDefaultLanguageOn);
                    window.localStorage.setItem("slid-user-has-set-stt-default-lang", "true");
                    if (!isDefaultLanguageOn) {
                      window.localStorage.setItem("slid-user-stt-default-lang", smartLiveTextLanguage);
                    } else {
                      window.localStorage.removeItem("slid-user-stt-default-lang");
                    }
                    trackEvent({
                      eventType: "Click LIVETEXT DEFAULT LANGUAGE in video note page",
                      eventProperties: { result_status: isDefaultLanguageOn ? "off" : "on", default_language: smartLiveTextLanguage },
                    });
                  }}
                >
                  <TextWrapper>
                    <Typography13 text={t("DefaultLanguage")} color={`--gray15`} />
                    <Typography11 text={t("DefaultLanguageDesc")} color={`--gray9`} />
                  </TextWrapper>
                  <ToggleButton toggleOn={isDefaultLanguageOn} />
                </STTItemWrapper>
                <LastSTTItemWrapper
                  onClick={() => {
                    trackEvent({ eventType: "Click LIVETEXT INFO in video note page" });
                    window.open(t("HowToUseSmartLiveTextLink"));
                  }}
                >
                  <Typography13 text={t("HowToUseSmartLiveText")} color={`--gray15`} />
                </LastSTTItemWrapper>
              </>
            )}
          </>
        ) : (
          <>
            <ExportOptionsWrapper>
              <ExportOptionsHeader>
                <ChevronLeftIconWrapper
                  onClick={() => {
                    setIsExportOptionOpened(false);
                  }}
                >
                  <Icon icon={`chevronLeft16`} color={`--gray9`} />
                </ChevronLeftIconWrapper>
                <ExportOptionsTitle text={t("ExportNoteOptions", { ns: "VideoNote" })} color={`--gray9`} />
              </ExportOptionsHeader>
              {renderPdfPrint()}
              <ExportOptionButton
                onClick={() => {
                  dispatch(setIsEditorOptionOpen(false));
                  if (!confirmPrivilege(SlidFeatures.wordExport)) return showInsufficientPrivilegeModal();
                  downloadWord({ target: isMobile ? "body" : "#editor-wrapper" });
                }}
              >
                <Icon icon={`word16`} color={`--gray9`} width={20} height={20} />
                <ExportOptionText text={`Word`} color={`--gray15`} />
              </ExportOptionButton>
              <ExportOptionButton
                onClick={() => {
                  dispatch(setIsEditorOptionOpen(false));
                  if (!confirmPrivilege(SlidFeatures.imageExport)) return showInsufficientPrivilegeModal();
                  downloadImages({ target: isMobile ? "body" : "#editor-wrapper" });
                }}
              >
                <Icon icon={`png16`} color={`--gray9`} width={20} height={20} />
                <ExportOptionText text={`Images`} color={`--gray15`} />
              </ExportOptionButton>
              <ExportOptionButton
                onClick={() => {
                  dispatch(setIsEditorOptionOpen(false));
                  if (!confirmPrivilege(SlidFeatures.markDownExport)) return showInsufficientPrivilegeModal();
                  downloadMarkdown({ target: isMobile ? "" : "#editor-wrapper" });
                }}
              >
                <Icon icon={`markdown16`} color={`--gray9`} width={20} height={20} />
                <ExportOptionText text={`Markdown`} color={`--gray15`} />
              </ExportOptionButton>
            </ExportOptionsWrapper>
          </>
        )}
      </DropdownMenuWrapper>
    </>
  );
};

const DropdownMenuButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray3);
  }
`;

const DropDownMenuBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
`;

const DropdownMenuWrapper = styled.div`
  width: 220px;
  position: absolute;
  right: 32px;
  top: 56px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;
  margin-bottom: 0;
  z-index: 999;
`;

const OpenRecentNoteOptionWrapper = styled.div`
  border-bottom: 1px solid var(--gray3);
  padding: 8px 0 4px 0;
`;

const OpenRecentNoteButton = styled(DropdownMenuButton)<{ isDemoMode: boolean }>`
  pointer-events: ${(props) => (props.isDemoMode ? "none" : "initial")};
  opacity: ${(props) => (props.isDemoMode ? "0.3" : "1")};
  justify-content: space-between;
`;

const OpenRecentNoteToggle = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleControl = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 2.2rem;
  user-select: none;
`;

const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: var(--blue8);

    &:after {
      left: 14px;
    }
  }
`;

const ControlButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 28px;
  border-radius: 8px;
  background-color: var(--gray5);
  transition: background-color 0.15s ease-in;

  &:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background: white;
    transition: left 0.15s ease-in;
  }
`;

const ButtonGroupWrapper = styled.div`
  border-bottom: 1px solid var(--gray3);
  padding: 4px 0;
`;

const IconContainer = styled.div`
  margin-right: 5px;
  display: flex;
`;

const UndoRedoButton = styled(DropdownMenuButton)`
  justify-content: space-between;
`;

const ButtonIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonShortcutText = styled(Typography11)``;

const ImportPDFButton = styled(DropdownMenuButton)<{ isDemoMode: boolean }>`
  pointer-events: ${(props) => (props.isDemoMode ? "none" : "initial")};
  opacity: ${(props) => (props.isDemoMode ? "0.3" : "1")};
`;

const ExportButton = styled(DropdownMenuButton)<{ isDemoMode: boolean }>`
  align-items: flex-start;
  padding: 8px 10px 8px 16px;
  position: relative;
  pointer-events: ${(props) => (props.isDemoMode ? "none" : "initial")};
  opacity: ${(props) => (props.isDemoMode ? "0.3" : "1")};
`;

const ExportChevronRightIconContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

const ButtonTextContainer = styled.div`
  width: 100%;
`;

const ExportDesc = styled.p`
  margin: 1px 0 0 0;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: var(--gray9);
`;

const MarkdownCopyButton = styled(DropdownMenuButton)<{ isDemoMode: boolean }>`
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: ${(props) => (props.isDemoMode ? "none" : "initial")};
  opacity: ${(props) => (props.isDemoMode ? "0.3" : "1")};
`;

const ShareNoteButton = styled(DropdownMenuButton)<{ isDemoMode: boolean }>`
  pointer-events: ${(props) => (props.isDemoMode ? "none" : "initial")};
  opacity: ${(props) => (props.isDemoMode ? "0.3" : "1")};
`;

const MyAccountButtonWrapper = styled.div<{ isDesktop: boolean }>`
  padding: ${({ isDesktop }) => (isDesktop ? "4px 0 " : "4px 0 8px 0")};
  border-bottom: ${({ isDesktop }) => (isDesktop ? "1px solid var(--gray3)" : "none")};
`;

const MyAccountButton = styled(DropdownMenuButton)``;

const LogoutButtonWrapper = styled.div`
  padding: 4px 0 8px 0;
`;

const LogoutButton = styled(DropdownMenuButton)``;

const ExportOptionsWrapper = styled.div`
  padding: 13px 0 245px 0;
`;

const ExportOptionsHeader = styled.div`
  padding: 0 11px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
`;

const ChevronLeftIconWrapper = styled.div`
  cursor: pointer;
`;

const ExportOptionsTitle = styled(Typography11)`
  && {
    margin: 0;
  }
`;

const ExportOptionButton = styled(DropdownMenuButton)``;

const ExportOptionText = styled(Typography13)`
  && {
    margin-left: 4px;
  }
`;

const RenderPDFOptionContainer = styled(DropdownMenuButton)``;

const STTMenuTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px 8px;
  border-top: 1px solid var(--gray3);
`;

const STTItemWrapper = styled(DropdownMenuButton)`
  display: flex;
`;

const LastSTTItemWrapper = styled(DropdownMenuButton)`
  display: flex;
  margin-bottom: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default EditorOptionDropdownMenu;
