// auth 2.0
import { Typography17, Typography13, Typography11, ColorTypes, Sticker } from "@slid/slid-ips";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "hooks";
import { trackEvent } from "utils/eventTracking";

const TabletStartCapturingButtonToolTip = ({ setShouldShowTabletCaptureGuideTooltipOnTouch }: { setShouldShowTabletCaptureGuideTooltipOnTouch: Function }) => {
  const { t } = useTranslation("VideoNote");
  const { userTabletDeviceInfo } = useAppSelector((state) => state.slidGlobal);

  const getTooltipImageAndText = () => {
    let imageType: string = "";
    let tooltipText: string = "";
    switch (userTabletDeviceInfo.tabletHomeButtonType) {
      case "WithoutHomeButton":
        imageType = "";
        tooltipText = t("TabletStartCapturingToolTipWithoutHomeButtonVersionText");
        break;
      case "WithHomeButtonA":
        imageType = `_home_buttonA`;
        tooltipText = t("TabletStartCapturingToolTipWithHomeButtonAVersionText");
        break;
      case "WithHomeButtonB":
        imageType = `_home_buttonB`;
        tooltipText = t("TabletStartCapturingToolTipWithHomeButtonBVersionText");
        break;
      default:
        break;
    }
    return (
      <>
        <img src={`/src/design/assets/slid_tablet_capture_guide${imageType}.png`} alt="" />
        <Typography13 text={tooltipText} color={`--gray17`} weight={400} textAlign="center" marginTop="20px" />
      </>
    );
  };

  return (
    <AnimatePresence>
      <TooltipContainer
        key={"tablet-capture-guide-tooltip"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={() => {
          trackEvent({
            eventType: "Close TAKING SCREENSHOT in video note page",
          });
          setShouldShowTabletCaptureGuideTooltipOnTouch(false);
        }}
        transition={{ duration: 0.5 }}
      >
        <ShortcutTipContainer>
          <Typography17 text={t("TabletCaptureShortcutText")} color={`--purple1_cloudy`} weight={700} style={{ marginRight: "4px" }} />
          <Sticker
            //@ts-ignore
            text={
              <StickerText>
                <Typography13 text="Cmd" color="--purple9" weight={700} />
                <Typography13 text=" / " color="--purple1_cloudy" weight={700} />
                <Typography13 text="Alt" color="--purple9" weight={700} />
              </StickerText>
            }
            color={ColorTypes.LightPurple}
          />
          <Typography11 text="+" color="--purple1_cloudy" weight={400} />
          <Sticker text="Shift" color={ColorTypes.Purple} />
          <Typography11 text="+" color="--purple1_cloudy" weight={400} />
          <Sticker text="3" color={ColorTypes.Purple} />
        </ShortcutTipContainer>
        {userTabletDeviceInfo && userTabletDeviceInfo.tabletHomeButtonType && getTooltipImageAndText()}
        <Tip></Tip>
      </TooltipContainer>
    </AnimatePresence>
  );
};

const TooltipContainer = styled(motion.div)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -40%);

  width: max-content;

  bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  padding: 24px 48px;
  border-radius: 12px;
  box-shadow: var(--boxShadow3);
  z-index: 998;

  gap: 8px;
`;

const ShortcutTipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--purple1_cloudy);
  margin-bottom: 20px;
`;

const Tip = styled.div`
  position: absolute;
  bottom: -8px;
  width: 16px;
  height: 16px;
  transform: rotate(-45deg);
  background-color: var(--white);
`;

const StickerText = styled.div`
  display: flex;
`;

export default TabletStartCapturingButtonToolTip;
