import React from "react";
import styled from "styled-components";
import { ColorTypes, Icon, Sticker, Tooltip } from "@slid/slid-ips";

import { useTranslation } from "react-i18next";

import { useAutoNotesButton } from "hooks/useAutoNotesButton";
import AutoNotesButtonIcon from "./AutoNotesButtonIcon";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";
import { useAppSelector } from "hooks";

const AutoNotesVideoNoteButton = () => {
  const { t } = useTranslation("AutoNotes");
  const { onMouseEnterButton, onMouseLeaveButton, onToggleAutoNotes, buttonStatus, isButtonHovered, onClickAutoNotesMoreButton } = useAutoNotesButton();
  const { isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);

  const handleClickAutoNotesVideoNoteButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    trackEvent({
      eventType: isAutoNotesToggledOn ? eventTypes.click.STOP_AUTO_NOTES_IN_VIDEO_NOTE_PAGE : eventTypes.click.AUTO_NOTES_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        location: "video note main button",
      },
    });
    onToggleAutoNotes();
  };

  return (
    <ButtonContainer onMouseEnter={onMouseEnterButton} onMouseLeave={onMouseLeaveButton} onClick={handleClickAutoNotesVideoNoteButton}>
      <AutoNotesButtonIcon isButtonHovered={isButtonHovered} buttonStatus={buttonStatus} />
      <ButtonText isGrayText={buttonStatus !== "generating"}>
        {isButtonHovered && buttonStatus !== "default"
          ? t("AutoNotesStop")
          : buttonStatus === "generating"
          ? t("AutoNotesGenerating")
          : buttonStatus === "waiting"
          ? t("AutoNotesWaiting")
          : t("AutoNotes")}
      </ButtonText>

      <StyledSticker text="BETA" color={ColorTypes.LightPurple} />
      <SettingButton onClick={onClickAutoNotesMoreButton}>
        <Tooltip title={t("AutoNotesSettings")} placement={"top"}>
          <Icon icon={`chevronDown20`} color={`--gray17`} />
        </Tooltip>
      </SettingButton>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button`
  // S of reset button
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;

  &:focus {
    outline: 0;
    border: none;
  }
  // E of reset button

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  width: 84px;
  height: 52px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

const ButtonText = styled.span<{ isGrayText: boolean }>`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: ${({ isGrayText }) => (isGrayText ? "var(--gray17)" : "var(--gray9)")};
`;

const SettingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 6px;
  width: 22px;
  height: 24px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray5);
  }
`;

const StyledSticker = styled(Sticker)`
  && {
    position: absolute;
    top: -12px;
    z-index: 1;
    padding: 0 4px;
    height: unset;
    margin-left: 6px;
    color: var(--purple);
    background-color: var(--purple1);

    p {
      font-size: 9px;
      line-height: 16px;
      color: var(--purple);
    }
  }
`;

export default AutoNotesVideoNoteButton;
