import { handleCodeNode } from "./CodeTypeParser";
import { parseMarkdownToDelimiter } from "./DelimiterTypeParser";
import { parseMarkdownToHeader } from "./HeaderTypeParser";
import { escapeHtml } from "./utils";
function generateUnorderedResult(ast, startIndentLevel = 0) {
  const result: any[] = [];

  function processList(listNode, indentLevel) {
    if (!listNode.children) return; // when item has no children.

    for (const child of listNode.children) {
      //NOTE: all children of a list node are listItems.
      if (child.type === "listItem") {
        //TODO: handle various kinds of listitem children
        //NOTE: we just need to convert to editor data format and push it in.
        // result.push({
        //   data: {
        //     items: [{ text: getNodeText(child).trim() }],
        //     indentLevel: indentLevel,
        //   },
        //   type: "unorderedList",
        // });

        for (const innerNode of child.children) {
          console.log("inner node in list item", innerNode);
          if (innerNode.type === "list" && !innerNode.ordered) {
            //NOTE: this processes a nested list at a higher indent level.
            //this will push to the global result array.
            processList(innerNode, indentLevel + 1);
          } else if (innerNode.type === "list" && innerNode.ordered) {
            //TODO: handle numbered list
            console.log("inner list node - ", innerNode);
            result.push(...generateOrderedResult(innerNode, indentLevel + 1));
          } else if (innerNode.type === "paragraph") {
            //maybe we should pass this result array to the paragraph node incase it has to add more than one item.
            //TODO: what if the paragraph has more than one item in it - like other children types that aren't text?
            result.push({
              data: {
                items: [{ text: handleParagraphNode(innerNode).trim() }],
                indentLevel: indentLevel,
              },
              type: "unorderedList",
            });
          } else if (innerNode.type === "code") {
            result.push(handleCodeNode(innerNode));
          } else if (innerNode.type === "html") {
            result.push({
              data: {
                items: [{ text: handleHtmlNode(innerNode) }],
                indentLevel: indentLevel,
              },
              type: "unorderedList",
            });
          } else if (innerNode.type === "thematicBreak") {
            result.push(parseMarkdownToDelimiter(innerNode));
          } else if (innerNode.type === "heading") {
            result.push(parseMarkdownToHeader(innerNode));
          } else {
            //TODO: log in sentry
            //NOTE: all nodes that can be nested must be supported
            //console.log("unknown node type in unordered list", innerNode);
          }
        }
      }
    }
  }

  processList(ast, startIndentLevel);
  return result;
}

function generateOrderedResult(ast, parentIndentLevel = 0) {
  const result: any[] = [];
  let numberingLevels = [
    [1, 1, 1],
    [1, 1, 1],
    [1, 1, 1],
  ];

  function processNode(node: any, parentIndentLevel: number) {
    if (node.type === "listItem") {
      for (const innerNode of node.children) {
        console.log("child in list item", innerNode);
        if (innerNode.type === "paragraph") {
          const listItem = {
            type: "orderedList",
            data: {
              items: [
                {
                  text: handleParagraphNode(innerNode),
                },
              ],
              indentLevel: parentIndentLevel,
              indentPhase: 0,
              numberingLevels: numberingLevels.map((level) => [...level]),
              numberForMarkdownDownloading: numberingLevels[0][parentIndentLevel],
            },
          };
          result.push(listItem);
          numberingLevels[0][parentIndentLevel]++;
        } else if (innerNode.type === "code") {
          result.push(handleCodeNode(innerNode));
        } else if (innerNode.type === "html") {
          result.push({
            data: {
              items: [{ text: handleHtmlNode(innerNode) }],
              indentLevel: parentIndentLevel,
              indentPhase: 0,
              numberingLevels: numberingLevels.map((level) => [...level]),
              numberForMarkdownDownloading: numberingLevels[0][parentIndentLevel],
            },
            type: "orderedList",
          });
          numberingLevels[0][parentIndentLevel]++;
        } else if (innerNode.type === "list" && innerNode.ordered) {
          //TODO: handle nested list.
          innerNode.children.forEach((child) => {
            processNode(child, parentIndentLevel + 1);
          });
        } else if (innerNode.type === "list" && !innerNode.ordered) {
          //TODO: handle nested unordered list.
          result.push(...generateUnorderedResult(innerNode, parentIndentLevel + 1));
        } else {
          //TODO: log in sentry
          //NOTE: all nodes that can be nested must be supported
          //console.log("unknown node type in unordered list", innerNode);
        }
      }
    }
  }

  ast.children.forEach((child: any) => {
    processNode(child, parentIndentLevel);
  });

  return result;
}

export function parseMarkdownToList(blocks) {
  if (blocks.ordered) {
    return generateOrderedResult(blocks);
  }
  return generateUnorderedResult(blocks);
}

//TODO: just write code to handle different nodes in the tree.
//NOTE: is it possible to find any other kind of node in here????
const handleParagraphNode = (node) => {
  console.log("paragraph node", node);
  let paragraphText = "";
  for (const content of node.children) {
    if (content.type === "text") {
      paragraphText += escapeHtml(content.value);
    } else if (content.type === "emphasis") {
      paragraphText += "<i>" + escapeHtml(content.children[0].value) + "</i>";
    } else if (content.type === "strong") {
      paragraphText += "<b>" + escapeHtml(content.children[0].value) + "</b>";
    } else if (content.type === "inlineCode") {
      paragraphText += `<code class="inline-code"> ${escapeHtml(content.value)} </code>`;
    } else if (content.type === "html") {
      paragraphText += escapeHtml(content);
    } else {
      //TODO: log in sentry.
      //TODO: check if paragraph can have any other kinds of children in markdown.
      //NOTE: other possibilities... "break", "link", "image", "code"
      console.log("unknown node type in paragraph", content);
    }
  }
  return paragraphText;
};

const handleHtmlNode = (node: { value: string; type: "html" }) => {
  const textContent = node.value;
  return escapeHtml(textContent);
};
