import React, { useEffect, useState } from "react";
import { IconWrapper, ResultItemContentWrapper, ResultItemWrapper } from "./SearchFolderItem";
import { Typography13, Typography15 } from "@slid/slid-ips";
import { FilledNoteIcon } from "components/icons/FilledNoteIcon";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { isTabletApp } from "utils/utils";
import { setShowSearchModal } from "redux/actions/searchActions";
import { useSearch } from "hooks";
import styled from "styled-components";
import HighlightText from "./HighlightText";
import { SlidNoteDocument } from "types/document";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

interface SearchNoteItemProps {
  document: SlidNoteDocument;
}

const SearchNoteItem = ({ document }: SearchNoteItemProps) => {
  const { t } = useTranslation("EditorComponent");
  const dispatch = useAppDispatch();
  const { searchKeywords } = useAppSelector((state) => state.search);
  const { applicationType, isTabletAppOpenedInSplitView } = useAppSelector((state) => state.slidGlobal);
  const { getSearchNotePathMap } = useSearch();
  const [notePathMap, setNotePathMap] = useState("");

  useEffect(() => {
    let notePathMap = getSearchNotePathMap();
    setNotePathMap(notePathMap[document["document_key"]] ? notePathMap[document["document_key"]] : "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <ResultItemWrapper
      to={isTabletApp() && isTabletAppOpenedInSplitView ? `/vdocs/${document.document_key}` : `/docs/${document.document_key}`}
      id={document.document_key}
      onClick={(event) => {
        trackEvent({
          eventType: eventTypes.click.NOTE_IN_SEARCH_RESULTS,
          eventProperties: {
            location: "search modal",
            from: "My Notes",
          },
        });
        dispatch(setShowSearchModal(false));
        if (event.metaKey && applicationType === "web") {
          window.open(`/docs/${document.document_key}`, "_blank");
        }
      }}
    >
      <IconWrapper>
        <FilledNoteIcon width={20} height={20} color="var(--gray7)" />
      </IconWrapper>
      <ResultItemContentWrapper>
        {!document.title ? (
          <Typography15 text={t("Untitled")} color={"--gray9"} weight={700} />
        ) : searchKeywords ? (
          <HighlightText highLightText={searchKeywords} contents={document.title} type={"title"} />
        ) : (
          <Typography15 text={document.title} color={"--gray9"} weight={700} />
        )}
        <Typography13 text={notePathMap} color={"--gray9"} />
        {document.thumbnail_text && searchKeywords ? (
          <HighlightText highLightText={searchKeywords} contents={document.thumbnail_text} type={"contents"} />
        ) : (
          <ContentText text={document.thumbnail_text} color={"--gray9"} />
        )}
      </ResultItemContentWrapper>
    </ResultItemWrapper>
  );
};

const ContentText = styled(Typography13)`
  && {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    max-height: 1.8rem;
  }
`;

export default SearchNoteItem;
