import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking/index";
import { showPaymentSuccessModal } from "utils/paymentModal/index";

import { sendAmplitudeData } from "utils/amplitude";
import env from "config/env";
import store from "redux/store";
import { createPaymentData, createPaymentDataForAppleIAP } from "redux/actions/pricingActions";
import { getUserLocationInfo } from "utils/utils";
import { SlackInterface_sendMessage } from "utils/slackInterface";

const PAYPLE_SDK_URL = env.end_point_url.payple_sdk_url;

export type trackPaymentEventProps = {
  membership: string;
  length?: number | undefined;
  amount: number | null;
  paymentKey: string;
  paymentGateway: "stripe" | "payple" | "apple";
  daypassSize?: number | undefined;
};

export const getTotalPrice = ({ membership, paymentLength }: any) => {
  const lang = store.getState().slidGlobal.lang;
  membership = membership.toLowerCase();
  if (lang === "ko") {
    switch (membership) {
      case "basic":
        switch (paymentLength) {
          case "monthly":
            return 8000;

          case "yearly":
            return 80000;
          default:
            return null;
        }

      case "pro":
        switch (paymentLength) {
          case "monthly":
            return 13000;

          case "yearly":
            return 130000;
          default:
            return null;
        }
      default:
        return null;
    }
  } else {
    switch (membership) {
      case "basic":
        switch (paymentLength) {
          case "monthly":
            return 8;

          case "yearly":
            return 80;
          default:
            return null;
        }
      case "pro":
        switch (paymentLength) {
          case "monthly":
            return 13;

          case "yearly":
            return 130;
          default:
            return null;
        }
      default:
        return null;
    }
  }
};

export const trackPaymentEvent = async ({ membership, length, amount, paymentKey, paymentGateway, daypassSize }: trackPaymentEventProps) => {
  const lang = await store.getState().slidGlobal.lang;
  const userData = await store.getState().slidGlobal.userData;
  const countryInfo = await getUserLocationInfo();
  const paymentMembership = daypassSize ? "24H Pass" : membership;

  trackEvent({
    eventType: eventTypes.success.MEMBERSHIP_PAYMENT,
    eventProperties: {
      membership: paymentMembership,
      length,
      size: daypassSize,
      paymentGateway,
      amount,
      paymentKey,
    },
  });

  sendAmplitudeData(`SLID_1_BUY_MEMBERSHIP`, {
    membership: paymentMembership,
    length,
    amount,
    size: daypassSize,
    lang,
    ...userData,
  });

  await SlackInterface_sendMessage({
    event: "SLID_2_NEW_PAYMENT",
    customMessage: {
      membership: paymentMembership,
      length: length ? length : daypassSize,
      amount,
      lang,
      paymentKey,
      paymentGateway,
      countryName: countryInfo.name,
      countryCode: countryInfo.country,
      ...userData,
    },
  });
};

export const getMembershipDates = ({ length, isDayPassPayment }: any) => {
  const date = new Date();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();
  let membershipStartDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  let membershipEndDate;
  const monthEndDate: any = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };
  let endYear = year;
  let endMonth = month;
  let endDay = day;
  while (length > 0) {
    length -= 1;
    endMonth += 1;
    if (endMonth > 12) {
      endMonth = 1;
      endYear += 1;
    }
  }
  if (day > monthEndDate[endMonth]) {
    endDay = monthEndDate[endMonth];
  } else {
    endDay = day;
  }
  if (isDayPassPayment) endYear += 1000; // For day-pass payment in IAP, set the end date to 1000 years later manually.
  membershipEndDate = `${endYear}-${endMonth}-${endDay} ${hour}:${minute}:${second}`;
  return {
    membershipStartDate,
    membershipEndDate,
  };
};

export const setPayment = () => {
  const jqueryScript = document.createElement("script");
  jqueryScript.src = "https://code.jquery.com/jquery-1.12.4.min.js";
  jqueryScript.async = true;

  jqueryScript.onload = () => {
    // setup payple
    const paypleScript = document.createElement("script");
    paypleScript.src = PAYPLE_SDK_URL;
    paypleScript.async = true;
    document.body.appendChild(paypleScript);
  };
  document.body.appendChild(jqueryScript);
};

export const addPayment = async ({ paymentInfo, paymentKey, amount, membership, promoType = "length", length, isRecurring, promotionId, paymentGateway, showModal, closeModal, deviceType }: any) => {
  const { lang } = store.getState().slidGlobal;
  const isDayPassPayment = [3900, 9900, 9.99, 3.99].includes(amount);

  trackPaymentEvent({ membership, length: !isDayPassPayment ? length : undefined, amount, paymentKey, paymentGateway, daypassSize: isDayPassPayment ? length : undefined });
  const { membershipStartDate, membershipEndDate } = getMembershipDates({ length });

  const newPaymentData = await store.dispatch(
    createPaymentData({
      data: {
        payment_info: paymentInfo,
        payment_gateway: paymentGateway,
        amount,
        payment_length: length,
        start_date: membershipStartDate,
        end_date: membershipEndDate,
        is_recurring: isRecurring,
        payment_key: paymentKey,
        membership,
        lang,
        promotion_id: promotionId,
      },
    })
  );

  if (!paymentKey.includes("referral")) {
    showPaymentSuccessModal({
      deviceType,
      showModal,
      closeModal,
      parameter: {
        membership,
        length,
      },
    });
  } else {
    return newPaymentData;
  }
};

export const addPaymentForAppleIAP = async ({
  paymentInfo,
  paymentKey,
  amount,
  membership,
  promoType = "length",
  length,
  isRecurring,
  promotionId,
  paymentGateway,
  showModal,
  closeModal,
  deviceType,
}: any) => {
  const { lang } = store.getState().slidGlobal;

  const isDayPassPayment = paymentInfo?.productId?.includes("24h_pass");
  trackPaymentEvent({ membership, length: !isDayPassPayment ? length : undefined, amount, paymentKey, paymentGateway, daypassSize: isDayPassPayment ? length : undefined });
  const { membershipStartDate, membershipEndDate } = getMembershipDates({ length, isDayPassPayment });

  await store.dispatch(
    createPaymentDataForAppleIAP({
      data: {
        payment_info: paymentInfo,
        payment_gateway: paymentGateway,
        amount,
        payment_length: length,
        start_date: membershipStartDate,
        end_date: membershipEndDate,
        is_recurring: isRecurring,
        payment_key: paymentKey,
        membership,
        lang,
        promotion_id: promotionId,
      },
    })
  );

  if (!paymentKey.includes("referral")) {
    showPaymentSuccessModal({
      deviceType,
      showModal,
      closeModal,
      parameter: {
        membership,
        length,
      },
    });
  }
};
