import { Typography28 } from "@slid/slid-ips";
import React, { memo } from "react";
import styled from "styled-components";
import Card from "./card";
import { useTranslation } from "react-i18next";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const CustomComponent = memo(() => {
  const { t } = useTranslation("ExtensionOnboardingModal");

  return (
    <Container>
      <TitleText weight={700} text={t("title")} color="--gray17" />
      <CardContainer>
        <Card
          image={`/src/design/assets/slid_extension_onboarding_modal_image1.webp`}
          iconSource={`/src/design/assets/slid_extension_onboarding_modal_icon1.webp`}
          title={t("card1.title", { keySeparator: "." })}
          description={t("card1.description", { keySeparator: "." })}
          buttonText={t("startButtonText")}
          onClick={() => {
            trackEvent({ eventType: eventTypes.click.START_FROM_YOUTUBE_IN_WEBSITE_SELECT_MODAL });
            window.location.assign(t("SlidGuideYoutubeLink", { ns: "common" }));
          }}
        />

        <Card
          image={`/src/design/assets/slid_extension_onboarding_modal_image2.webp`}
          iconSource={`/src/design/assets/slid_extension_onboarding_modal_icon2.webp`}
          title={t("card2.title", { keySeparator: "." })}
          description={t("card2.description", { keySeparator: "." })}
          buttonText={t("startButtonText")}
          onClick={() => {
            trackEvent({ eventType: eventTypes.click.START_FROM_OTHER_WEBSITES_IN_WEBSITE_SELECT_MODAL });
            window.open("/video-note/new", "_self");
          }}
        />
      </CardContainer>
    </Container>
  );
});

const Container = styled.div`
  box-sizing: border-box;
  width: 896px;
  height: 666px;
  background: var(--white);
  border-radius: 30px;
  padding: 0 90px;
  padding-top: 62px;
`;

const TitleText = styled(Typography28)`
  && {
    letter-spacing: -0.1rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  margin-top: 26px;
  width: 886px;
  height: 200px;
  gap: 32px;
`;

export default CustomComponent;
