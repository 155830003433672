import React from "react";
import { Typography15, Icon } from "@slid/slid-ips";
import styled from "styled-components";
import { ISLTSupportedLanguage } from "../supportedLangs";

const LanguageSelectMenu = ({
  isLangListOpen,
  onToggleLangList,
  listTitle,
  supportedLanguages,
  onSelectLanguage,
  selectedLanguage,
}: {
  selectedLanguage: ISLTSupportedLanguage;
  onSelectLanguage: (language: ISLTSupportedLanguage) => void;
  supportedLanguages: ISLTSupportedLanguage[];
  isLangListOpen: boolean;
  onToggleLangList: () => void;
  listTitle: string;
}) => {
  return (
    <LanguageSelectContainer>
      <LanguageSelectHeader
        onClick={() => {
          onToggleLangList();
        }}
      >
        <Typography15 type={`span`} text={listTitle} color={`--white`} weight={700} />
        <HeaderSelectedLanguageContainer>
          {/* icon */}
          {selectedLanguage.icon && <selectedLanguage.icon width={16} height={17} />}
          {/* text */}
          <Typography15 type={`span`} text={selectedLanguage.text} color={`--white`} weight={700} style={{ marginLeft: `8px` }} />
          <Icon icon={`${isLangListOpen ? "chevronUp16" : "chevronDown16"}`} color={`--gray7`} style={{ marginLeft: `10px` }} />
        </HeaderSelectedLanguageContainer>
      </LanguageSelectHeader>

      {isLangListOpen && (
        <LanguageOptionsWrapper>
          {supportedLanguages.map((language: ISLTSupportedLanguage, index) => (
            <LangListItem isSelected={language.text === selectedLanguage.text} onClick={() => onSelectLanguage(language)}>
              {language?.icon && <language.icon width={16} height={17} />}
              <Typography15 type={`span`} text={language.text} color={`--gray5`} weight={400} />
              {language.subText && <Typography15 type={`span`} text={language.subText} weight={400} color={`--gray10`} />}
              {language.text === selectedLanguage.text && <Icon icon={`check24`} color={`--blue7`} style={{ marginLeft: `auto` }} />}
            </LangListItem>
          ))}
        </LanguageOptionsWrapper>
      )}
    </LanguageSelectContainer>
  );
};

const LanguageSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--SGray-Colors-SGray15, #343a40);
  background: var(--SGray-Colors-SGray17, #212529);
`;
const LanguageSelectHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const HeaderSelectedLanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const LanguageOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const LangListItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: ${({ isSelected }) => (isSelected ? "#343A40" : "transparent")};
  border-radius: 8px;

  &:hover {
    background-color: #343a40;
  }
`;

export default LanguageSelectMenu;
