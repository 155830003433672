import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { Typography15, Icon } from "@slid/slid-ips";

import { setShowSearchPopup } from "redux/actions/vdocsActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { sendAmplitudeData } from "utils/amplitude";
import { isTouchDevice } from "utils/utils";
import { isAndroid } from "react-device-detect";
import env from "config/env";
import useEditorStore from "store/useEditorStore";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
interface EditorPlaceholderProps {
  saveDocument: () => Promise<void>;
}

const EditorPlaceholder = ({ saveDocument }: EditorPlaceholderProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation(["EditorComponent", "SweetAlertModals"]);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { showEditorPlaceholder } = useEditorStore();
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { fileUploadInputRef } = useAppSelector((state) => state.editor);
  const { editorInstance } = useAppSelector((state) => state.vdocs);
  const isDemoMode = document.location.href === `${SLID_WEB_APP_URL}/demo`;
  const mode = useRef(location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs");

  const focusOnInitialBlock = () => {
    (document.getElementsByClassName("ce-paragraph cdx-block")[0] as HTMLElement)?.focus();
  };

  const handleClickPlaceholder = () => {
    if (!confirmPrivilege(SlidFeatures.noteEditing) && applicationType !== "slidpocket") return showInsufficientPrivilegeModal();
    focusOnInitialBlock();
    editorInstance?.focus();
  };

  const handleClickRecentNote = () => {
    sendAmplitudeData(`Click open recent note`, {
      origin: mode.current,
    });
    if (!confirmPrivilege(SlidFeatures.noteCreation)) return showInsufficientPrivilegeModal();
    dispatch(setShowSearchPopup(true));
  };

  const handleClickPDFUpload = async () => {
    // [PDF] This method force click the invisible input element <PDFFileUploader /> to open file system for selecting pdf file.
    sendAmplitudeData(`Click upload pdf`, {
      origin: mode.current,
    });
    if (!confirmPrivilege(SlidFeatures.pdfUpload)) return showInsufficientPrivilegeModal();
    await saveDocument();
    fileUploadInputRef.current?.click();
  };

  const handleClickClickableMask = () => {
    {
      /* give focus to initialBlock when empty area below editor is pressed: */
    }
    focusOnInitialBlock();
  };

  if (!showEditorPlaceholder) return null;
  return (
    <PlaceholderWrapper>
      <PlaceholderContainer data-testid="editor-placeholder" isSlidPocket={applicationType === "slidpocket"}>
        {applicationType === "slidpocket" ? (
          <Typography15 text={t("PlaceholderMobile", { ns: "EditorComponent" })} color={`--gray7`} onClick={handleClickPlaceholder} className="slid-pocket-placeholder" />
        ) : (
          <PlaceholderDescText text={t("PlaceholderText", { ns: "EditorComponent" })} color={`--gray7`} onClick={handleClickPlaceholder} />
        )}
        {!isDemoMode && applicationType !== "slidpocket" && (
          <>
            <PlaceholderButton onClick={handleClickRecentNote}>
              <PlaceholderIconContainer>
                <Icon icon={`recentNote20`} color={`--gray7`} />
              </PlaceholderIconContainer>
              <Typography15 text={t("ContinueWithRecentNote", { ns: "EditorComponent" })} color={`--gray7`} />
            </PlaceholderButton>
            {/** Temporarily hide upload PDF feature for Apple touchDevices:  */}
            {(!isTouchDevice() || (isTouchDevice() && isAndroid)) && (
              <PlaceholderButton onClick={handleClickPDFUpload} data-testid={"upload-pdf-button"}>
                <PlaceholderIconContainer>
                  <Icon icon={`import20`} color={`--gray7`} />
                </PlaceholderIconContainer>
                <Typography15 text={t("ImportPDFFile", { ns: "EditorComponent" })} color={`--gray7`} />
              </PlaceholderButton>
            )}
          </>
        )}
      </PlaceholderContainer>
      <ClickableMask onClick={handleClickClickableMask} />
    </PlaceholderWrapper>
  );
};

const PlaceholderWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  height: 100%;
  z-index: 1; // to make placeholder clickable
`;

const PlaceholderContainer = styled.div<{ isSlidPocket: boolean }>`
  width: 87%;
  max-width: 700px;
  color: var(--gray7);

  ${({ isSlidPocket }) =>
    isSlidPocket && // unset width for slidpocket
    css`
      width: 100%;
      max-width: 100%;
      padding: 0 16px;
    `}
`;

const PlaceholderDescText = styled(Typography15)`
  && {
    padding-bottom: 26px;
  }
`;

const PlaceholderButton = styled.div`
  position: relative;
  left: -5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 4px;
  padding: 5px 6px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }
`;

const PlaceholderIconContainer = styled.div`
  margin-right: 8px;
`;

const ClickableMask = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

export default EditorPlaceholder;
