import { useState } from "react";
import { useHistory } from "react-router-dom";
import { showPlanUpgradeNeededModal } from "../modal/index";
import USER_PRIVILEGES, { MembershipType, SlidFeatures } from "utils/privilegeManager";
import store from "redux/store";
import { showPlanNeededModal } from "../modal";
import { useModal } from "@slid/slid-ips";

export const useConfirmPrivilege = () => {
  const { showModal, closeModal } = useModal();
  const history = useHistory();
  const [isUserHasPrivilege, setIsUserHasPrivilege] = useState<boolean>(false);

  const confirmPrivilege = (privilege: SlidFeatures) => {
    const isFeatureAllowed = USER_PRIVILEGES.has(privilege);
    setIsUserHasPrivilege(isFeatureAllowed);
    return isFeatureAllowed;
  };

  const showInsufficientPrivilegeModal = (mustShowModal?: boolean) => {
    const userData = store.getState().slidGlobal.userData;

    if (mustShowModal) {
      return showPlanNeededModal({ showModal, closeModal, history });
    }
    if (!isUserHasPrivilege) {
      if (userData?.payment === MembershipType.break) return showPlanNeededModal({ showModal, closeModal, history });
      else return showPlanUpgradeNeededModal({ showModal, closeModal, history });
    }
  };

  return { confirmPrivilege, showInsufficientPrivilegeModal };
};
