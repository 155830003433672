import { useAppSelector } from "hooks";
import { useEffect } from "react";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import { sendIsSTTSessionStartedToParentWindow } from "utils/extensionInterface/sendToExtension";
import { setSTTSocketConnectionStatusListener, setSTTErrorListener, setTranscriptResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { v4 as uuidv4 } from "uuid";

export const useInitializeSLT = () => {
  const { setTranscriptData, isSTTToggledOn, setIsManualAddingMode, setSttError, isSTTActive, startSTT, stopSTT, setIsSTTSessionStarted, setIsSTTSocketConnected } = useWhisperSLTStore();

  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo } = useAppSelector((state) => state.vdocs);

  useEffect(() => {
    const startSTTSession = () => {
      const shouldStartSLT = isExtensionMediaPermitted && isSTTToggledOn && (currentVideo?.videoType === "iframe" || isVideoPlaying);

      if (shouldStartSLT) {
        if (!isSTTActive) {
          startSTT();
        }
        sendIsSTTSessionStartedToParentWindow(true);
        setIsSTTSessionStarted(true);
      } else if (isSTTActive) {
        stopSTT();
        sendIsSTTSessionStartedToParentWindow(false);
        setIsSTTSessionStarted(false);
      }
    };

    startSTTSession();
  }, [isVideoPlaying, isExtensionMediaPermitted, isSTTToggledOn, currentVideo]);

  useEffect(() => {
    setSLTDefaultOptions();
  }, []);

  // set listeners for extension events
  useEffect(() => {
    setSTTSocketConnectionStatusListener({
      responseHandler: ({ isSTTSocketConnected }: { isSTTSocketConnected: boolean }) => {
        setIsSTTSocketConnected(isSTTSocketConnected);
      },
    });
    setTranscriptResponseListener({
      responseHandler: (receivedData: any) => {
        const newTranscriptBlock = {
          id: uuidv4(),
          text: receivedData.text,
        };
        setTranscriptData(newTranscriptBlock);
      },
    });
    setSTTErrorListener({
      responseHandler: (receivedData: any) => {
        setSttError(receivedData.error);
      },
    });
  }, []);

  const setSLTAddingModeFromUserDefaultAddingMode = () => {
    const isManualAddingMode = window.localStorage.getItem("slid-stt-manual-adding-mode");
    if (isManualAddingMode === "true") {
      setIsManualAddingMode(true);
    } else {
      setIsManualAddingMode(false);
    }
  };

  const setSLTDefaultOptions = () => {
    setSLTAddingModeFromUserDefaultAddingMode();
  };

  return {};
};
