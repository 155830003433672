import React from "react";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import { useModalStore } from "store/useModalStore";
import styled from "styled-components";
import { Button, Typography14, Typography18 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { isIOS } from "utils/utils";
import env from "config/env";

const NewMobileAppGuideModal = () => {
  const { hideModal } = useModalStore();
  const { t } = useTranslation("Modal");

  return (
    <ModalPortal target={"body"}>
      <BaseModalContainer
        callback={() => {
          hideModal();
        }}
        closable={true}
      >
        <ModalContainer>
          <Typography18 text={t("NewMobileAppGuideModal.title", { keySeparator: "." })} color={"--gray18"} weight={700} textAlign="center" />
          <Typography14 text={t("NewMobileAppGuideModal.description", { keySeparator: "." })} color={"--gray18"} marginTop="8px" textAlign="center" />
          <Button
            text={t("NewMobileAppGuideModal.primaryButton", { keySeparator: "." })}
            callback={() => {
              isIOS() ? window.open(env.links.slid_new_mobile_ios, "_blank") : window.open(env.links.slid_new_mobile_android, "_blank");
            }}
            appearance="primary"
            size="medium"
            marginTop="24px"
          />
        </ModalContainer>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContainer = styled.div`
  z-index: 9999999;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  box-shadow: var(--boxShadow1);
  width: auto;
  min-width: 300px;
`;

export default NewMobileAppGuideModal;
