import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography11, Typography13, Typography14 } from "@slid/slid-ips";
import { useAppSelector } from "hooks";

interface MobileAutoNoteViewProps {
  data: { text?: string; isLoading: boolean };
  editorAPI: any;
  currentBlock: any;
}

const MobileAutoNoteView = ({ data, editorAPI, currentBlock }: MobileAutoNoteViewProps) => {
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const [isLandscape, setIsLandscape] = useState(false);

  const handleClickAutoNoteBlock = () => {
    const currentBlockIndex = editorAPI.blocks.getBlockIndex(currentBlock.id);
    const formattedText = data?.text?.replace(/- /g, "- \u200B");
    editorAPI.blocks.delete(currentBlockIndex);
    editorAPI.blocks.insert("paragraph", { text: formattedText }, {}, currentBlockIndex, true);
    editorAPI.caret.setToBlock(currentBlockIndex, "end");
  };

  useEffect(() => {
    const getIsLandscape = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const isLandscape = searchParams.get("isLandscape") === "true";
      setIsLandscape(isLandscape);
    };

    getIsLandscape();
  }, []);

  if (data.isLoading || !data.text) {
    return (
      <LoadingBlockWrapper className={`${editorAPI.styles.block}`}>
        <img src="/src/icons/icon_auto-note-star.svg" alt="auto-note-star" />
        {isLandscape ? (
          <Typography11 text={lang === "ko" ? "자동 필기 중.." : "Generating auto note.."} color={"--red6"} weight={500} />
        ) : (
          <Typography14 text={lang === "ko" ? "자동 필기 중.." : "Generating auto note.."} color={"--red6"} weight={500} />
        )}
        <ParagraphContainer data-type={`auto-note-text`} />
      </LoadingBlockWrapper>
    );
  }

  return (
    <>
      <NewBlockIndicator>
        <IndicatorLine />
        {isLandscape ? (
          <Typography11 text={lang === "ko" ? "새 필기" : "New Note"} color={"--red5"} weight={700} />
        ) : (
          <Typography13 text={lang === "ko" ? "새 필기" : "New Note"} color={"--red5"} weight={700} />
        )}
        <IndicatorLine />
      </NewBlockIndicator>
      <Wrapper className={`${editorAPI.styles.block} ce-paragraph`} onClick={handleClickAutoNoteBlock}>
        <ParagraphContainer data-type={`auto-note-text`}>{data?.text?.replace(/- /g, "- \u200B") || ""}</ParagraphContainer>
      </Wrapper>
    </>
  );
};

export default MobileAutoNoteView;

const LoadingBlockWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: var(--red1);
  margin: 1.2rem 0;
`;

const NewBlockIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.2rem 0;
  width: 100%;
  gap: 0.8rem;
  flex: 1;
`;

const IndicatorLine = styled.div`
  height: 1px;
  flex: 1;
  background: var(--red5);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ParagraphContainer = styled.p`
  font-size: inherit;
  outline: none;
  white-space: pre-wrap;
  -webkit-touch-callout: none;
  word-break: keep-all;
  word-wrap: break-word;
`;
