//Auth 2.0
import React from "react";
import videoDocumentStyles from "components/VideoDocument/VideoDocumentEditor.module.scss";
import store from "redux/store";
import i18n from "config/i18n/i18n";
import Sweetalert, { SweetAlertOptions } from "sweetalert2";
import { isMobile } from "react-device-detect";
import { renderToString } from "react-dom/server";
import { isMobileApp } from "../../utils/utils";

const { t } = i18n;

export const alertImageMarkupNotSaved = (editorWrapperClassName: string) => {
  return Sweetalert.fire({
    target: `.${editorWrapperClassName}`,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: "error",
    title: t("MarkupSaveFailed", { ns: "EditorComponent" }),
    html: t("MarkupSaveFailedDesc", { ns: "EditorComponent" }),
    confirmButtonText: t("Close", { ns: "EditorComponent" }),
  });
};

export const alertExtensionMediaPermissionGranted = (jsxAlert: React.ReactElement) => {
  return Sweetalert.fire({
    target: `#editor-wrapper`,
    heightAuto: false,
    customClass: {
      container: "swal-custom-toast-container",
    },
    html: renderToString(jsxAlert),
    width: 360,
    padding: 0,
    timer: 3000,
    showConfirmButton: false,
    scrollbarPadding: false,
  });
};

export const alertAutoSavingError = () => {
  return Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: `error`,
    title: t("AutoSaveFailed", { ns: "SweetAlertModals" }),
    html: t("AutoSaveFailedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("Refresh", { ns: "SweetAlertModals" }),
    showCancelButton: true,
    cancelButtonText: t("Close", { ns: "SweetAlertModals" }),
  } as SweetAlertOptions);
};

export const alertUploadingPDF = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: "Loading...",
    html: t("UploadingPDF", { ns: "SweetAlertModals" }),
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertUploadingPDFFailed = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: `error`,
    title: t("UploadingPDFFailed", { ns: "SweetAlertModals" }),
    html: t("UploadingPDFFailedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};
export const alertProcessingDownloadingPDF = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("PDFDownloadProcessing", { ns: "SweetAlertModals" }),
    html: isMobileApp() ? t("PDFDownloadProcessingMobileDesc", { ns: "SweetAlertModals" }) : t("PDFDownloadProcessingDesc", { ns: "SweetAlertModals" }),
    allowOutsideClick: false,
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};
export const alertDownloadingPDFFailed = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: `error`,
    title: t("PDFDownloadFailed", { ns: "SweetAlertModals" }),
    html: t("PDFDownloadFailedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertOnlyPDFTypeIsAllowed = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: `error`,
    title: t("OnlyPDFTypeIsAllowed", { ns: "SweetAlertModals" }),
    html: t("OnlyPDFTypeIsAllowedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertAnalyzingTextsFromImage = ({ target }: { target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: "Loading...",
    html: t("AnalyzingTextsFromImage", { ns: "SweetAlertModals" }),
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertAnalyzingTextsFailed = ({ target }: { target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: `error`,
    title: t("AnalyzingTextsFailed", { ns: "SweetAlertModals" }),
    html: t("AnalyzingTextsFailedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertUploadingImage = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: "Loading...",
    html: t("UploadImage", { ns: "SweetAlertModals" }),
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertUploadingImageFailed = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: `error`,
    title: t("UploadImageFailed", { ns: "SweetAlertModals" }),
    html: t("UploadImageFailedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertScreenshotNotSaved = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: "error",
    title: t("ScreenshotNotSaved", { ns: "SweetAlertModals" }),
    html: t("ScreenshotNotSavedDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertPDFTooLarge = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: "error",
    title: t("PDFTooManyPages", { ns: "SweetAlertModals" }),
    html: t("PDFTooManyPagesDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: "Close",
  });
};

export const alertImageTooLarge = () => {
  Sweetalert.fire({
    target: "#editor-wrapper",
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    icon: "error",
    title: t("ImageTooLarge", { ns: "SweetAlertModals" }),
    html: t("ImageTooLargeDesc", { ns: "SweetAlertModals" }),
    confirmButtonText: "Close",
  });
};

export const alertEmptyNoteExporting = ({ target }: { target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("EmptyDocument", { ns: "SweetAlertModals" }),
    html: t("EmptyDocumentDesc", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertEmptyNoteSharing = ({ target }: { target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("EmptyDocument", { ns: "SweetAlertModals" }),
    html: t("EmptyDocumentSharingDesc", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertEmptyNoteCopyingMarkdown = ({ target }: { target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("EmptyDocument", { ns: "SweetAlertModals" }),
    html: t("EmptyDocumentCopyingDesc", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertNoteContentCopied = ({ isMacOs, target }: { isMacOs: boolean; target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("CopyNoteTitle", { ns: "SweetAlertModals" }),
    html: t(isMobile ? "CopyNoteDescMobile" : "CopyNoteDesc", { ns: "SweetAlertModals", os: isMacOs ? "cmd" : "ctrl" }),
    timer: 1500,
    showConfirmButton: false,
    scrollbarPadding: false,
  });
};

export const alertEmptyNoteExportingAsImage = ({ target }: { target: string }) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("EmptyDocument", { ns: "SweetAlertModals" }),
    html: t("EmptyDocumentImage", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("close", { ns: "SweetAlertModals" }),
  });
};

export const alertCreatingWordFile = ({ target }: { target: string }) => {
  Sweetalert.fire({
    title: "Loading...",
    html: t("CreatingDocxFile", { ns: "SweetAlertModals" }),
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertCreatingImageFiles = ({ target }: { target: string }) => {
  Sweetalert.fire({
    title: "Loading...",
    html: t("CreatingImageFiles", { ns: "SweetAlertModals" }),
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertPDFDownloadingDesc = ({ target }: { target: string }) => {
  Sweetalert.fire({
    title: "Loading...",
    html: t("PDFDownloadDesc", { ns: "SweetAlertModals" }),
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertRestoreDocument = ({ target }: any) => {
  return Sweetalert.fire({
    target: `.${target}`,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: t("RestoreConfirm", { ns: "SweetAlertModals" }),
    cancelButtonText: t("RestoreCancel", { ns: "SweetAlertModals" }),
    title: t("RestoreDocumentTitle", { ns: "SweetAlertModals" }),
    html: t("RestoreDocumentDesc", { ns: "SweetAlertModals" }),
    icon: "info",
  } as SweetAlertOptions);
};

export const alertProcessing = ({ clickableDim }: { clickableDim?: boolean } = {}) => {
  Sweetalert.fire({
    title: t("InProgressStatement", { ns: "common" }),
    allowOutsideClick: clickableDim !== null ? clickableDim : true,
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertEnterNewFolderName = ({ target }: any) => {
  return Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("EnterNewFolderNameTitle", { ns: "SweetAlertModals" }),
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
      placeholder: t("EnterNewFolderNamePlaceholder", { ns: "SweetAlertModals" }),
    },
    showCancelButton: true,
    confirmButtonText: t("EnterNewFolderNameConfirm", { ns: "SweetAlertModals" }),
    cancelButtonText: t("EnterNewFolderNameCancel", { ns: "SweetAlertModals" }),
  } as SweetAlertOptions);
};

export const alertCreatingNewFolder = ({ target }: any) => {
  Sweetalert.fire({
    title: t("CreatingNewFolderTitle", { ns: "SweetAlertModals" }),
    html: t("CreatingNewFolderHtml", { ns: "SweetAlertModals" }),
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    didOpen: () => {
      Sweetalert.showLoading();
    },
  });
};

export const alertRenameDocument = ({ target, documentTitle }: any) => {
  return Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("RenameDocumentTitle", { ns: "SweetAlertModals" }),
    text: t("RenameDocumentText", { ns: "SweetAlertModals" }),
    input: "text",
    inputValue: documentTitle,
    showCancelButton: true,
    cancelButtonText: t("RenameDocumentCancel", { ns: "SweetAlertModals" }),
    confirmButtonText: t("RenameDocumentConfirm", { ns: "SweetAlertModals" }),
  } as SweetAlertOptions);
};

export const alertSignInFirst = ({ willCloseCallback }: any) => {
  return Sweetalert.fire({
    title: t("SignInFirstTitle", { ns: "SweetAlertModals" }),
    html: t("SignInFirstHtml", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("SignInFirstConfirm", { ns: "SweetAlertModals" }),
    cancelButtonText: t("SignInFirstCancel", { ns: "SweetAlertModals" }),
    showCancelButton: true,
    willClose: () => {
      willCloseCallback();
    },
  } as SweetAlertOptions);
};

export const alertRenameDocumentError = ({ target }: any) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("RenameDocumentErrorTitle", { ns: "SweetAlertModals" }),
    html: t("RenameDocumentErrorHtml", { ns: "SweetAlertModals" }),
    icon: `error`,
    confirmButtonText: t("RenameDocumentErrorConfirm", { ns: "SweetAlertModals" }),
  });
};

export const alertDeleteDocumentError = ({ target }: any) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("DeleteDocumentErrorTitle", { ns: "SweetAlertModals" }),
    html: t("DeleteDocumentErrorHtml", { ns: "SweetAlertModals" }),
    icon: `error`,
    confirmButtonText: t("DeleteDocumentErrorConfirm", { ns: "SweetAlertModals" }),
  });
};

export const alertDeleteDocumentCheck = ({ target, title, isFolder }: any) => {
  const { lang } = store.getState().slidGlobal;
  const noteTitle = title ? title : lang === "ko" ? "제목없는 노트" : "Untitled";

  return Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      header: "p-1",
      content: "p-1",
      container: "position-absolute",
    },
    title: t("DeleteDocumentCheckTitle", { ns: "SweetAlertModals", noteType: lang === "ko" ? (isFolder ? "폴더" : "노트") : isFolder ? "Folder" : "Note" }),
    html: t("DeleteDocumentCheckHtml", { ns: "SweetAlertModals", title: noteTitle, noteType: lang === "ko" && isFolder ? "폴더" : "노트" }),
    showConfirmButton: false,
    showCancelButton: true,
    showDenyButton: true,
    denyButtonText: t("DeleteDocumentCheckDeny", { ns: "SweetAlertModals" }),
    cancelButtonText: t("DeleteDocumentCheckCancel", { ns: "SweetAlertModals" }),
  } as SweetAlertOptions);
};

export const alertRenameFolder = ({ target, currentTitle }: any) => {
  return Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("RenameFolderTitle", { ns: "SweetAlertModals" }),
    text: t("RenameFolderText", { ns: "SweetAlertModals" }),
    input: "text",
    inputValue: currentTitle,
    showCancelButton: true,
    cancelButtonText: t("RenameFolderCancel", { ns: "SweetAlertModals" }),
    confirmButtonText: t("RenameFolderConfirm", { ns: "SweetAlertModals" }),
  } as SweetAlertOptions);
};

export const alertRenameFolderError = ({ target }: any) => {
  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("RenameFolderErrorTitle", { ns: "SweetAlertModals" }),
    html: t("RenameFolderErrorHtml", { ns: "SweetAlertModals" }),
    icon: `error`,
    confirmButtonText: t("RenameFolderErrorConfirm", { ns: "SweetAlertModals" }),
  });
};

export const alertPaymentProcessing = () => {
  Sweetalert.fire({
    title: "Your payment is pending ... ",
    didOpen: () => {
      Sweetalert.showLoading();
    },
    allowOutsideClick: false,
  });
};

export const alertRestartSlid = () => {
  Sweetalert.fire({
    title: t("RestartSlidDesktopTitle", { ns: "SweetAlertModals" }),
    html: t("RestartSlidDesktopBody", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("RestartSlidDesktopPrimaryButton", { ns: "SweetAlertModals" }),
    showCancelButton: true,
    cancelButtonText: t("RestartSlidDesktopSecondaryButton", { ns: "SweetAlertModals" }),
    customClass: {
      container: "position-absolute",
    },
    target: `.${videoDocumentStyles[`video-document-container`]}`,
    heightAuto: false,
  }).then((result: any) => {
    if (result.isConfirmed) {
      window.parent.postMessage("IFRAME_TO_PRIMARY_RESTART", "*");
    }
  });
};

export const alertClipRecordingNotAvailable = ({ target, location }: any) => {
  Sweetalert.fire({
    target: `${target}`,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("ClipRecordingNotAvailableTitle", { ns: "SweetAlertModals" }),
    html: t("ClipRecordingNotAvailableBody", { ns: "SweetAlertModals", location }),
    icon: "info",
    confirmButtonText: t("Okay", { ns: "SweetAlertModals" }),
  });
};

export const alertMaximumNoteSizeReached = ({ target }: any) => {
  Sweetalert.fire({
    target: `${target}`,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("MaximumNoteSizeReachedTitle", { ns: "SweetAlertModals" }),
    html: t("MaximumNoteSizeReachedBody", { ns: "SweetAlertModals" }),
    icon: "info",
    confirmButtonText: t("Okay", { ns: "SweetAlertModals" }),
  });
};
