// auth 2.0
import axios from "axios";
import env from "config/env";

const SlackNewVideoWebHookUrl = env.end_point_url.slack_new_video;
const SlackNewPaymentWebHookUrl = env.end_point_url.slack_new_payment;

export const SlackInterface_sendMessage = async ({ event, customMessage }) => {
  if (env.currentEnv !== "production") return;
  let message = "";
  let webHookUrl = "";
  switch (event) {
    case "SLID_2_NEW_VIDEO":
      message = `🚀 Slid 2.0 New Video!\n\n - Website Url: ${customMessage.originUrl} \n - Video Url: ${customMessage.videoUrl} \n - Video Type: ${customMessage.videoType} \n - Document Key: ${customMessage.documentKey}`;
      webHookUrl = SlackNewVideoWebHookUrl;
      break;
    case "SLID_2_NEW_PAYMENT":
      let amount = null;
      if (customMessage.paymentGateway === "payple") {
        amount = `${customMessage.amount}원`;
      } else if (customMessage.countryCode === "IN") {
        amount = `₹${customMessage.amount}`;
      } else {
        amount = `$${customMessage.amount}`;
      }
      message = `💵 *New Payment* 💵\n
  *[Payment]*
  - Amount: ${amount}
  - Membership: ${customMessage.membership}
  - Length: ${customMessage.length}
  - Payment Key: ${customMessage.paymentKey}\n
  *[User Info]*
  - Email: ${customMessage.email}
  - Country: ${customMessage.countryName}
  - # of captures: ${customMessage.clip_cnt}
  - # of notes: ${customMessage.document_cnt}
  `;
      webHookUrl = SlackNewPaymentWebHookUrl;
      break;
    case "SLID_2_B2B_PAYMENT":
      message = `✨ Slid B2B Payment!
      - amount: ${customMessage.lang === "ko" ? `${customMessage.totalAmount}원` : `$${customMessage.totalAmount}`}
      - quantity: ${customMessage.quantity}
      - membership: ${customMessage.membership}
      - length: ${customMessage.duration}
      - name: ${customMessage.name}
      - email: ${customMessage.email}`;
      webHookUrl = SlackNewPaymentWebHookUrl;
      break;
    default:
      return;
  }
  if (!message) return;
  await axios.post(
    webHookUrl,
    JSON.stringify({
      type: "mrkdwn",
      text: message,
    }),
    {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post["Content-Type"];
          return data;
        },
      ],
    }
  );
};
