import { escapeHtml } from "./utils";

export function parseMarkdownToHeader(node) {
  let headerData = {};

  switch (node.depth) {
    case 1:
      node.children.forEach((item) => {
        headerData = {
          data: {
            level: 2,
            text: escapeHtml(item.value),
          },

          type: "header1",
        };
      });

      return headerData;
    case 2:
      node.children.forEach((item) => {
        headerData = {
          data: {
            level: 3,
            text: escapeHtml(item.value),
          },
          type: "header2",
        };
      });

      return headerData;
    case 3:
      node.children.forEach((item) => {
        headerData = {
          data: {
            level: 4,
            text: escapeHtml(item.value),
          },

          type: "header3",
        };
      });

      return headerData;
    case 4:
      node.children.forEach((item) => {
        headerData = {
          data: {
            level: 4,
            text: escapeHtml(item.value),
          },

          type: "header3",
        };
      });

      return headerData;
    case 5:
      node.children.forEach((item) => {
        headerData = {
          data: {
            level: 4,
            text: escapeHtml(item.value),
          },
          type: "header3",
        };
      });

      return headerData;
    case 6:
      node.children.forEach((item) => {
        headerData = {
          data: {
            level: 4,
            text: escapeHtml(item.value),
          },
          type: "header3",
        };
      });

      return headerData;
    default:
      break;
  }
}
