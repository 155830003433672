export enum PaymentGateway {
  "payple" = "payple",
  "stripe" = "stripe",
}

/**
 * Membership types for client-side
 * Think of it as the 'display' version of ServerMembershipTypes (User perspective)
 */
export enum UserMembership {
  "break" = "break",
  "trial" = "trial",
  "basic" = "basic",
  "pro" = "pro",
  "premium" = "premium",
  "standard" = "standard",
  "daypass" = "daypass",
}
export type UserMembershipTypes = keyof typeof UserMembership;

/**
 * Membership types for server-side
 * Think of it as the 'permission' version of UserMembershipTypes (Server/System perspective)
 */
export enum ServerMembership {
  "break" = "break",
  "trial" = "trial",
  "basic" = "basic",
  "pro" = "pro",
  "premium" = "premium",
  "standard" = "standard",
}
export type ServerMembershipTypes = keyof typeof ServerMembership;

export interface PaymentResponseDataType {
  payment_key: string;
  amount: number;
  start_date: string;
  end_date: string;
  is_recurring: boolean;
  membership: ServerMembershipTypes;
  lang: "ko" | "en";
  is_expired: boolean;
  promotion: any; // TODO: add type for this
  payment_gateway: keyof typeof PaymentGateway;
  is_pending: boolean;
  more_info: string; // TODO: make this a JSON type
}

/**
 * Sync with models.py::DayPass
 */
export interface DayPassResponseDataType {
  day_pass_key: string;
  user: any; // TODO: add type for this
  payment: any; // TODO: add type for this
  is_started: boolean;
  is_expired: boolean;
  started_at: string;
  expired_at: string;
  more_info: string | null;
}

export interface SlidUser {
  user_key: string;
  email: string;
  is_temporary: boolean;
  username: string;
  country: string;
  login_type: string;
  payment: UserMembershipTypes;
  clip_cnt: number;
  clip_recording_cnt: number;
  document_cnt: number;
  created_time: string;
  last_action_time: string;
  promotion_id: string | null;
  more_info: string;
}
